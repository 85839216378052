import React, { useEffect } from "react";
import DataTable from "../common/DataTable";
import Pagination from "../common/Pagination";
import {
  view_all_table,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/interviews_enum";
import {
  useAllInterviewsByCondition,
  useAllInterviewsStats,
} from "../../shared/hooks/UseInterview";
import ExportComponent from "../common/ExportComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";

function AllInterviewComponent({ label, value }) {
  const [data, setPageNumber, setParams, deleteBtnClicked] =
    useAllInterviewsByCondition();
  const [statsdata, setParamss] = useAllInterviewsStats();

  const {
    loading,
    interviews,
    total_interviews,
    page,
    pages,
    interviews_stats,
  } = data;
  console.log("Interview Stats", interviews_stats);
  useEffect(() => {
    if (value) {
      setParams([{ label, value }]);
      setParamss([{ label, value }]);
    }

    // setParamsCandidate([{ label: "client_name", value: match.params.id }]);
  }, [value]);

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (interviews) {
      convertToReadable(interviews, inputFields);
    }
  }, [interviews]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Interviews", "interviews");
  };

  return (
    <>
      <ExportComponent handleOnExport={handleOnExport} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
            >
              <div className="card-header">
                <div className="card-title">Interviews</div>
              </div>
              <div className="card-body">
                {total_interviews} records found
                <div>
                  <DataTable
                    keys={view_all_table}
                    data={interviews}
                    field={LINK_URL}
                    page={page}
                    deleteBtnClicked={deleteBtnClicked}
                    loading={loading}
                    inputFields={inputFields}
                    PAGE_TITLE={PAGE_TITLE}
                    PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    hideEditBtn={true}
                    hideDeleteBtn={true}
                    hideQVBtn={true}
                  />
                  <Pagination
                    data={interviews}
                    page={page}
                    pages={pages}
                    count={total_interviews}
                    setPage={setPageNumber}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllInterviewComponent;
