import api from "../../domain/api";
import {
  GET_ADMINS,
  ADD_ADMIN,
  EDIT_ADMIN,
  GET_ADMIN,
  RESET_ADMIN,
  GET_ALL_ADMINS,
  ERROR_ADMIN,
} from "../types/admin_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addAdmin = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/admins`, formData);
    dispatch({
      type: ADD_ADMIN,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAdmins =
  ({
    termField = "",
    term = "",
    pageNumber = "",
    start_from = "",
    start_to = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_ADMIN,
      });
      const { data } = await api.get(
        `/admins?searchTerm=${termField}&keyword=${term}&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}`
      );

      dispatch({
        type: GET_ADMINS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ADMIN,
    });
    const { data } = await api.get(`/admins/${id}`);

    dispatch({
      type: GET_ADMIN,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editAdmin = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/admins/${id}`, formData);
    dispatch({
      type: EDIT_ADMIN,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteAdmin = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/admins/${id}`);
    dispatch(setAlert("Admin Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllAdmins =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`/admins/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_ADMINS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_ADMIN });
};
