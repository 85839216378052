import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCandidatestatus,
  getCandidatestatuss,
  getCandidatestatus,
  editCandidatestatus,
  deleteCandidatestatus,
  getAllCandidatestatuss,
} from "../../store/actions/candidatestatus_action";
import { SEARCH_TERMS } from "../enums/candidatestatuss_enum";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllCandidatestatuss = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidatestatus);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCandidatestatuss({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCandidatestatus(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getCandidatestatuss({
        pageNumber,
      })
    );
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleCandidatestatus = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidatestatus);
  useEffect(() => {
    dispatch(getCandidatestatus(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCandidatestatus = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addCandidatestatus(data));
  };
  return [addData];
};
export const useUpdateCandidatestatus = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editCandidatestatus(id, data));
  };
  return [updateData];
};

export const useSelectAllCandidatestatus = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.candidatestatus);
  useEffect(() => {
    dispatch(getAllCandidatestatuss({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
