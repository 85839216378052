import moment from "moment";

export const PAGE_TITLE = "Categories";
export const PAGE_SINGLE_TITLE = "Category";
export const LINK_URL = "categories";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  content: {
    type: "html",
    required: false,
    title: "Content",
  },

};
export const initialValues = {
  name: "",
  content: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Content", value: "content" },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "name",
    label: "Name",
    type: "string",
  },

]
