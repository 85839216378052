import moment from "moment";
export const PAGE_TITLE = "Interviews";
export const PAGE_SINGLE_TITLE = "Interview";
export const LINK_URL = "interviews";
export const SEARCH_TERMS = ["interview_name", "meta_title"];
export const inputFields = {
  interview_type: {
    type: "string",
    required: true,
    title: "Interview Name",
  },
  candidate: {
    type: "related",
    required: false,
    title: "Candidate",
    field: "name",
    hideOnEntry: true,
  },
  posting_title: {
    type: "related",
    required: false,
    title: "Posting Title",
    field: "posting_title",
  },
  client: {
    type: "related",
    required: false,
    title: "Client",
    field: "client_name",
    hideOnEntry: true,
  },
  recruiter: {
    type: "related",
    required: false,
    title: "Recruiter",
    field: "name",
    // hideOnEntry: true,
  },
  from: {
    type: "string",
    required: false,
    title: "From",
    // inputType: "date-localtime",
    inputType: "datetime-local",
  },
  // to: {
  //   type: "string",
  //   required: false,
  //   title: "To",
  //   // inputType: "date",
  //   inputType: "datetime-local",
  // },
  interviewer: {
    type: "string",
    required: false,
    title: "Interviewer",
    // field: "name",
  },
  location: {
    type: "string",
    required: false,
    title: "Location",
  },
  schedule_comments: {
    type: "text",
    required: false,
    title: "Schedule Comment",
  },
  candidate_status: {
    type: "related",
    required: false,
    title: "Candidate Status",
    static: true,
  },
  interview_type: {
    type: "select",
    required: true,
    title: "Interview Type",
    inputType: "",
    options: ["Round 1", "Round 2", "Final Round"],
    field: "",
  },
  interview_mode: {
    type: "select",
    required: true,
    title: "Interview Mode",
    inputType: "",
    options: ["Face to Face", "Telephonic", "Virtual"],
    field: "",
  },
  interview_status: {
    type: "select",
    required: true,
    title: "Interview Status",
    inputType: "",
    options: ["Round 1 Cleared", "Round 2 Cleared", "Final", "Rejected", "Backout"],
    field: "",
  },

  // upload_cv: {
  //   type: "file",
  //   required: false,
  //   title: "Upload CV",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // assessment_name: {
  //     type: "string",
  //     required: true,
  //     title: "Assessment Name",
  // },
};
export const initialValues = {
  interview_name: "",
  candidate_name: "",
  posting_title: "",
  from: moment().format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  location: "",
  schedule_comments: "",
  candidate_status: "",
  // assessment_name: "",
};

export const view_all_table = [
  { name: "Interview Type", value: "interview_type" },

  {
    name: "Posting",
    value: "posting_title",
    related: true,
    field: "posting_title",
    is_link: true,
    link_field: "jobposts",
  },
  {
    name: "Client",
    value: "client",
    related: true,
    field: "client_name",
    is_link: true,
    link_field: "clients",
  },

  { name: "From", value: "from", date: true },
  {
    name: "Candidate",
    value: "candidate",
    related: true,
    field: "name",
    is_link: true,
    link_field: "candidates",
  },
  { name: "Candidate Status", value: "candidate_status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "interview_name",
    field: "interview_name",
    label: "Interview Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "candidate_name",
    field: "candidate_name",
    label: "Candidate Name",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "posting_title",
    field: "posting_title",
    label: "Posting Title",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "from",
    field: "from",
    label: "From",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "to",
    field: "to",
    label: "To",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "interview",
    field: "interview",
    label: "Interview",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "location",
    field: "location",
    label: "Location",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "candidate_status",
    field: "candidate_status",
    label: "Candidate Status",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
];
