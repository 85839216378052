import api from "../../domain/api";
import {
    GET_DOCUMENTS,
    ADD_DOCUMENT,
    EDIT_DOCUMENT,
    GET_DOCUMENT,
    RESET_DOCUMENT,
    GET_ALL_DOCUMENTS,
    ERROR_DOCUMENT,
} from "../types/document_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addDocument = (formData) => async (dispatch, getState) => {
    try {
        const { data } = await api.post(`/documents`, formData);
        dispatch({
            type: ADD_DOCUMENT,
            payload: data,
        });
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getDocuments =
    ({ pageNumber = "" }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: RESET_DOCUMENT,
                });
                const queryParams = qs.parse(window.location.search.replace("?", ""));
                const query = qs.stringify(queryParams, {
                    encodeValuesOnly: true, // prettify url
                });
                const { data } = await api.get(
                    `/documents?&pageNumber=${pageNumber}&${query}`
                );

                dispatch({
                    type: GET_DOCUMENTS,
                    payload: data,
                });
            } catch (error) {
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };
export const getDocument = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_DOCUMENT,
        });
        const { data } = await api.get(`/documents/${id}`);

        dispatch({
            type: GET_DOCUMENT,
            payload: data,
        });
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const editDocument = (id, formData) => async (dispatch) => {
    try {
        const { data } = await api.put(`/documents/${id}`, formData);
        dispatch({
            type: EDIT_DOCUMENT,
            payload: data,
        });
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const deleteDocument = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/documents/${id}`);
        dispatch(setAlert("Document Deleted Successfully", "success"));
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getAllDocuments =
    ({ term, value }) =>
        async (dispatch) => {
            try {
                const { data } = await api.get(
                    `/documents//all?term=${term}&value=${value}`
                );

                dispatch({
                    type: GET_ALL_DOCUMENTS,
                    payload: data,
                });
            } catch (error) {
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };

export const handleErrorLocal = () => async (dispatch) => {
    dispatch({ type: ERROR_DOCUMENT });
};
