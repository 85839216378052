import React, { useEffect } from "react";
import DataTable from "../common/DataTable";
import Pagination from "../common/Pagination";
import {
  view_all_table as view_all_candidates,
  LINK_URL,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
} from "../../shared/enums/candidates_enum";

import { useAllCandidatesByCondition } from "../../shared/hooks/UseCandidate";
import { useAllJobpostsByCondition } from "../../shared/hooks/UseJobpost";
import ExportComponent from "../common/ExportComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";

function AllCandidatesComponent({ label, value }) {
  const [
    candidate_data,
    setPageNumberCandidate,
    setParamsCandidate,
    deleteBtnClicked,
  ] = useAllCandidatesByCondition();
  useEffect(() => {
    // setParams([{ label, value }]);
    setParamsCandidate([{ label: label, value: value }]);
  }, [value]);

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (candidate_data.candidates) {
      convertToReadable(candidate_data.candidates, inputFields);
    }
  }, [candidate_data.candidates]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Candidates", "candidates");
  };

  return (
    <>
      <ExportComponent handleOnExport={handleOnExport} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
            >
              <div className="card-header">
                <div className="card-title">Candidates</div>
              </div>
              <div className="card-body">
                {candidate_data && candidate_data.total_candidates} records
                found
                <div>
                  <DataTable
                    keys={view_all_candidates}
                    data={candidate_data.candidates}
                    field={LINK_URL}
                    page={candidate_data.page}
                    deleteBtnClicked={deleteBtnClicked}
                    loading={candidate_data.loading}
                    inputFields={inputFields}
                    PAGE_TITLE={PAGE_TITLE}
                    PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    hideEditBtn={true}
                    hideDeleteBtn={true}
                    hideQVBtn={true}
                  />
                  <Pagination
                    data={candidate_data.candidates}
                    page={candidate_data.page}
                    pages={candidate_data.pages}
                    count={candidate_data.total_candidates}
                    setPage={setPageNumberCandidate}
                    loading={candidate_data.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCandidatesComponent;
