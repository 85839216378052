import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addNotification,
  getNotifications,
  getNotification,
  editNotification,
  deleteNotification,
  getAllNotifications,
} from "../../store/actions/notification_action";
import { SEARCH_TERMS } from "../enums/notifications_enum";
import _debounce from "lodash/debounce";




export const useSelectAllNotification = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);
  return [data];
};
