import {
  GET_JOBPOSTS,
  ADD_JOBPOST,
  EDIT_JOBPOST,
  GET_JOBPOST,
  RESET_JOBPOST,
  GET_ALL_JOBPOSTS,
  ERROR_JOBPOST,
  INTERVIEWS_BY_JOBPOST,
  INTERVIEWS_STATS_BY_JOBPOST,
  CANDIDATES_BY_JOBPOST,
  JOBPOST_STATS_STARTED,
  JOBPOST_STATS,
  JOBPOST_STATS_ENDED
} from "../types/jobpost_type";

const initialState = {
  jobposts: null,
  page: null,
  pages: null,
  jobpost: null,
  total_jobposts: 0,
  loading: true,
  error: {},
  jobpost_message: null,
  all_jobposts: null,
  interviews_by_jobpost: null,
  interviews_by_jobpost_stats: null,
  candidates_by_jobposts: null,
  jobposts_stats_loading: true,
  jobposts_stats: null,
};

export const jobpost_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_JOBPOSTS:
      return {
        ...state,
        jobposts: payload.jobposts,
        pages: payload.pages,
        page: payload.page,
        total_jobposts: payload.count,
        loading: false,
        error: {},
      };
    case JOBPOST_STATS_STARTED:
      return {
        ...state,
        jobposts_stats: null,
        jobposts_stats_loading: true,
      };
    case JOBPOST_STATS:
      return {
        ...state,
        jobposts_stats: payload,
      };
    case JOBPOST_STATS_ENDED:
      return {
        ...state,
        jobposts_stats_loading: false,
      };
    case GET_ALL_JOBPOSTS:
      return {
        ...state,
        all_jobposts: payload,
        loading: false,
        error: {},
      };

    case INTERVIEWS_BY_JOBPOST:
      return {
        ...state,
        interviews_by_jobpost: payload,
        loading: false,
        error: {},
      };

    case RESET_JOBPOST:
      return {
        ...state,
        jobpost: null,
        loading: true,
        error: {},
      };

    case ADD_JOBPOST:
      return {
        ...state,
        jobpost_message: payload,
        loading: false,
        error: {},
      };
    case GET_JOBPOST:
      return {
        ...state,
        jobpost: payload,
        loading: false,
        error: {},
      };
    case EDIT_JOBPOST:
      return {
        ...state,
        jobpost_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_JOBPOST:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
