import api from "../../domain/api";
import {
  GET_ATTENDANCES,
  ADD_ATTENDANCE,
  EDIT_ATTENDANCE,
  GET_TODAY_ATTENDANCE,
  GET_MONTHLY_ATTENDANCE,
  GET_TODAY_ATTENDANCE_EMPLOYEE,
  GET_MONTHLY_ATTENDANCE_EMPLOYEE,
  ERROR_ATTENDANCE,
  RESET_ATTENDANCE,
} from "../types/attendance_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addAttendance = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/attendances`, formData);
    dispatch({
      type: ADD_ATTENDANCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAttendances =
  ({ pageNumber = "" }) =>

    async (dispatch) => {
      try {
        dispatch({
          type: RESET_ATTENDANCE,
        });
        const queryParams = qs.parse(window.location.search.replace("?", ""));
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true, // prettify url
        });
        const { data } = await api.get(
          `/attendances?&pageNumber=${pageNumber}&${query}`
        );
        console.log("DATA", data);

        dispatch({
          type: GET_ATTENDANCES,
          payload: data,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const getTodayAttendance = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TODAY_ATTENDANCE,
    });
    const { data } = await api.get(`/attendances/today`);

    dispatch({
      type: GET_TODAY_ATTENDANCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getMonthlyAttendance = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MONTHLY_ATTENDANCE,
    });
    const { data } = await api.get(`/attendances/monthly-attendances`);

    dispatch({
      type: GET_MONTHLY_ATTENDANCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getTodayAttendanceEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TODAY_ATTENDANCE_EMPLOYEE,
    });
    const { data } = await api.get(`/attendances/today-employee`);

    dispatch({
      type: GET_TODAY_ATTENDANCE_EMPLOYEE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getMonthlyAttendanceEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MONTHLY_ATTENDANCE_EMPLOYEE,
    });
    const { data } = await api.get(`/attendances/monthly-attendances`);

    dispatch({
      type: GET_MONTHLY_ATTENDANCE_EMPLOYEE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editAttendance = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/attendances/${id}`, formData);
    dispatch({
      type: EDIT_ATTENDANCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteAttendance = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/attendances/${id}`);
    dispatch(setAlert("Attendance Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_ATTENDANCE });
};
