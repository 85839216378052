export const GET_ATTENDANCES = "GET_ATTENDANCES";
export const ADD_ATTENDANCE = "ADD_ATTENDANCE";
export const EDIT_ATTENDANCE = "EDIT_ATTENDANCE";
export const GET_TODAY_ATTENDANCE = "GET_TODAY_ATTENDANCE";
export const GET_MONTHLY_ATTENDANCE = "GET_MONTHLY_ATTENDANCE";
export const GET_TODAY_ATTENDANCE_EMPLOYEE = "GET_TODAY_ATTENDANCE_EMPLOYEE";
export const GET_MONTHLY_ATTENDANCE_EMPLOYEE = "GET_MONTHLY_ATTENDANCE_EMPLOYEE";
export const ERROR_ATTENDANCE = "ERROR_ATTENDANCE";
export const RESET_ATTENDANCE = "RESET_ATTENDANCE";


