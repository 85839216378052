import {
    GET_DOCUMENTS,
    ADD_DOCUMENT,
    EDIT_DOCUMENT,
    GET_DOCUMENT,
    RESET_DOCUMENT,
    GET_ALL_DOCUMENTS,
    ERROR_DOCUMENT,
} from "../types/document_type";

const initialState = {
    documents: null,
    page: null,
    pages: null,
    document: null,
    total_documents: 0,
    loading: true,
    error: {},
    document_message: null,
    all_documents: null,
};

export const document_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_DOCUMENTS:
            return {
                ...state,
                documents: payload.documents,
                pages: payload.pages,
                page: payload.page,
                total_documents: payload.count,
                loading: false,
                error: {},
            };
        case GET_ALL_DOCUMENTS:
            return {
                ...state,
                all_documents: payload,
                loading: false,
                error: {},
            };

        case RESET_DOCUMENT:
            return {
                ...state,
                document: null,
                loading: true,
                error: {},
            };

        case ADD_DOCUMENT:
            return {
                ...state,
                document_message: payload,
                loading: false,
                error: {},
            };
        case GET_DOCUMENT:
            return {
                ...state,
                document: payload,
                loading: false,
                error: {},
            };
        case EDIT_DOCUMENT:
            return {
                ...state,
                document_message: payload,
                loading: false,
                error: {},
            };
        case ERROR_DOCUMENT:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
};
