import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DoughnutChart from "../../components/charts/DonughtChart";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useAllJobposts } from "../../shared/hooks/UseJobpost";
import { useAllInterviews } from "../../shared/hooks/UseInterview";
import {
  useAllEmployees,
  useSelectAllEmployee,
} from "../../shared/hooks/UseEmployee";
import DataTable from "../../components/common/DataTable";
import Spinner from "../../components/layout/Spinner";
import TableViewBtn from "../../components/common/TableViewBtn";
import { useSelectAllDashboard } from "../../shared/hooks/UseDashboard";
import List from "./list";
import JobList from "./JobList";
import moment from "moment";
import { UseFilter } from "../../shared/hooks/UseFilter";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { getAllHolidays } from "../../store/actions/holiday_action";
import { useAllHolidays } from "../../shared/hooks/UseHoliday";
import { useSelector, useDispatch } from "react-redux";
import UpcomingBirthdays from "../../components/dashboard/UpcomingBirthdays";
import UpcomingJobanniversarys from "../../components/dashboard/UpcomingJobanniversarys";
import Confetti from "react-confetti";
import greet from "./greet.js";
import AddActivityForm from "./AddActivityForm";
import AllActivitys from "./AllActivitys";
import {
  useAllCandidates,
  useGetDropdownOptions,
  useAllCandidatesStats,
} from "../../shared/hooks/UseCandidate";
// const GreetingMessage = greet("Shubbu");

const Dashboard = ({ }) => {
  const userData = useSelector((state) => state.auth);

  const { user } = userData;

  const [holidaydata] = useAllHolidays();

  const [data] = useAllJobposts();
  const [interviewData] = useAllInterviews();
  const [statsdata, setParams] = useAllCandidatesStats();
  const { candidates_stats_loading, candidates_stats } = statsdata;
  const [dashboardData, fromDate, setFromDate, toDate, setToDate] =
    useSelectAllDashboard();
  const [employeeData] = useSelectAllEmployee();
  const { all_dashboards, all_dashboards_loading } = dashboardData;
  console.log("ALL DASHBOARDS", all_dashboards);

  const { loading, jobposts, total_jobposts } = data;
  const { holidays, total_holidays } = holidaydata;
  const { interviews, total_interviews } = interviewData;
  const { all_employees, total_employee } = employeeData;

  const Today = (person) => {
    let currentDay = new Date().getDate();
    let currentMonth = new Date().getMonth();

    let filter =
      person &&
      person.filter((data) => {
        // console.log(data);
        let day = new Date(data.dob).getDate();
        let month = new Date(data.dob).getMonth();

        // console.log("DAY", day);
        // console.log("MONTH", month);

        return currentDay == day && currentMonth == month;
      });
    return filter;
  };
  const TodayDOJ = (person) => {
    let currentDay = new Date().getDate();
    let currentMonth = new Date().getMonth();

    let filter =
      person &&
      person.filter((data) => {
        // console.log(data);
        let day = data.doj && new Date(data.doj).getDate();
        let month = data.doj && new Date(data.doj).getMonth();

        // console.log("DAY", day);
        // console.log("MONTH", month);

        return currentDay == day && currentMonth == month;
      });
    return filter;
  };

  // upcoming birthdays
  const Upcoming = (person, toMonth) => {
    let currentMonth = new Date().getMonth();
    let currentDay = new Date().getDate();

    console.log("CM -", currentMonth);
    console.log("CD -", currentDay);

    let filter =
      person &&
      person.filter((data) => {
        let month = new Date(data.dob).getMonth();
        let day = new Date(data.dob).getDate();

        console.log("M -", month);
        console.log("D -", day);

        if (currentDay == day) return;
        return month >= currentMonth && month <= currentMonth + toMonth;
      });

    return filter;
  };
  const UpcomingDOJ = (person, toMonth) => {
    let currentMonth = new Date().getMonth();
    let currentDay = new Date().getDate();

    let filter =
      person &&
      person.filter((data) => {
        let month = data.doj && new Date(data.doj).getMonth();
        let day = data.doj && new Date(data.doj).getDate();

        if (currentDay == day) return;
        return month >= currentMonth && month <= currentMonth + toMonth;
      });

    return filter;
  };
  // console.log("TODAY", Today(employees));

  // const [activeBtn, todayDate, weekStartDate, weekEndsDate] = UseFilter();
  // console.log(window.location.);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Dashboard"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />
        <FilterDateComponent link="/dashboard" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <h4>Candidate</h4>
                </div>
                {
                  all_dashboards && all_dashboards.interview_status && all_dashboards.interview_status.map((item) => {
                    return (
                      <div className="col-md-3">
                        <Link to={`/candidates?exact[candidate_status]=${item._id}`}></Link>
                        <div className="dashboard-stat pending">
                          <div className="report-title">{item._id} </div>
                          <div className="report-stat">
                            {item.count}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                {/* <div className="col-md-3">
                  <div className="dashboard-stat pending">
                    <div className="report-title">Joined </div>
                    <div className="report-stat">
                      {all_dashboards && all_dashboards.candidate_joined
                        ? all_dashboards.candidate_joined
                        : 0}
                    </div>
                  </div>
                </div> */}

              </div>
              {/* <div className="row">
                {candidates_stats && candidates_stats.filter((item) => { })}
                <div className="col-md-3">
                  <Link
                    to={`/candidates?exact[candidate_status]=JOINED&${window.location.search &&
                      window.location.search.length > 0
                      ? window.location.search.split("?")[1]
                      : ""
                      }`}
                  >
                    <div className="dashboard-stat pending">
                      <div className="report-title">Joined </div>
                      <div className="report-stat">
                        {all_dashboards && all_dashboards.candidate_joined
                          ? all_dashboards.candidate_joined
                          : 0}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link
                    to={`/candidates?exact[candidate_status]=OFFER%20ACCEPTED&${window.location.search &&
                      window.location.search.length > 0
                      ? window.location.search.split("?")[1]
                      : ""
                      }`}
                  >
                    <div className="dashboard-stat pending">
                      <div className="report-title">Offer Accepted</div>
                      <div className="report-stat">
                        {all_dashboards &&
                          all_dashboards.candidate_offer_accepted
                          ? all_dashboards.candidate_offer_accepted
                          : 0}
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}

              {
                // <h4>Requirements</h4>
                // <div className="row">
                //   <div className="col-md-3">
                //     <Link
                //       to={`/jobPosts?exact[job_opening_status]=Closed%20By%20Accord&${window.location.search &&
                //         window.location.search.length > 0
                //         ? window.location.search.split("?")[1]
                //         : ""
                //         }`}
                //     >
                //       <div className="dashboard-stat pending">
                //         <div className="report-title">Closed By Accord </div>
                //         <div className="report-stat">
                //           {all_dashboards && all_dashboards.jobpost_closed
                //             ? all_dashboards.jobpost_closed
                //             : 0}
                //         </div>
                //       </div>
                //     </Link>
                //   </div>
                // </div>
              }

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Jobposts</div>
                    </div>
                    <div className="card-body ">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Job Post</th>
                              <th>Client</th>
                              <th>Opened Date</th>
                              <th>Target Date</th>
                              <th>Assigned Recruiter</th>
                              <th>Status</th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading ? (
                              jobposts &&
                              jobposts.slice(0, 10).map((jobpost, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {jobpost.posting_title}{" "}
                                      <Link
                                        to={`/jobposts/${jobpost._id}/view`}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>{" "}
                                    </td>
                                    <td>
                                      {jobpost.client_name &&
                                        jobpost.client_name ? (
                                        <>
                                          {" "}
                                          {jobpost.client_name.client_name}{" "}
                                          <Link
                                            to={`/clients/${jobpost.client_name._id}/view`}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>{" "}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{jobpost.date_opened}</td>
                                    <td>{jobpost.target_date}</td>
                                    <td>
                                      {jobpost.assigned_recruiter ? (
                                        <>
                                          {jobpost.assigned_recruiter.name}{" "}
                                          <Link
                                            to={`/employees/${jobpost.assigned_recruiter._id}/view`}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>{" "}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{jobpost.job_opening_status}</td>
                                    <td>
                                      <Link
                                        to={`/jobposts/${jobpost._id}/view`}
                                        className="btn btn-success"
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={20} className="text-center">
                                  <Spinner />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Interviews</div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Interview Type</th>
                              <th scope="col">Candidate Name</th>
                              <th scope="col">Client Name</th>
                              <th scope="col">Candidate Status</th>
                              <th scope="col">Date</th>
                              <th scope="col">Recruiter</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading ? (
                              interviews &&
                              interviews.map((interview, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{interview.interview_type} </td>
                                    <td>
                                      {interview.candidate &&
                                        interview.candidate.name ? (
                                        <>
                                          {interview.candidate.name}
                                          <Link
                                            to={`/candidates/${interview.candidate._id}/view`}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        </>
                                      ) : (
                                        "-"
                                      )}{" "}
                                    </td>
                                    <td>
                                      {interview.client &&
                                        interview.client.client_name ? (
                                        <>
                                          {interview.client.client_name}
                                          <Link
                                            to={`/clients/${interview.client._id}/view`}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        </>
                                      ) : (
                                        "-"
                                      )}{" "}
                                    </td>
                                    <td>{interview.candidate_status} </td>
                                    <td>
                                      {interview.from &&
                                        moment(interview.from).format(
                                          "DD-MM-YYYY"
                                        )}{" "}
                                    </td>

                                    <td>
                                      {interview.recruiter &&
                                        interview.recruiter.name}{" "}
                                    </td>
                                    <td>
                                      <Link
                                        className="btn btn-success"
                                        to={`/interviews/${interview._id}/view`}
                                      >
                                        <i className="fa fa-eye" />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={20} className="text-center">
                                  <Spinner />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <div className="dashboard-stat">
                  <p className="text-dark title"> Todays Birthday </p>
                  <hr />
                  <List info={Today(all_employees)}></List>
                </div>
                <div className="dashboard-stat">
                  <p className="text-dark title"> Todays Job Anniversary </p>
                  <hr />
                  <JobList info={TodayDOJ(all_employees)}></JobList>
                </div>
              </div>
              <div className="dashboard-stat">
                <AddActivityForm />
              </div>
              <div className="dashboard-stat">
                <AllActivitys />
              </div>
              <div className="dashboard-stat">
                {all_dashboards && (
                  <DoughnutChart stats={all_dashboards.interview_stats} />
                )}
              </div>
              <div className="dashboard-stat">
                <p className="text-dark title"> Holidays </p>
                <hr />

                {holidays &&
                  holidays.map((holiday, index) => {
                    return (
                      <div className="holidays">
                        <p>
                          <strong>
                            {index + 1} . {holiday.name} :{" "}
                            {moment(holiday.holiday_date).format("DD-MM-YYYY")}
                          </strong>
                        </p>
                      </div>
                    );
                  })}
                {
                  // <List info={Today(all_employees)}></List>
                }
              </div>


              <div className="dashboard-stat">
                <div className="board">
                  <p className="upcoming text-dark">Upcoming Birthday</p>
                  <hr />
                  <UpcomingBirthdays employees={all_employees} />
                </div>
              </div>


              <div className="dashboard-stat">
                <div className="board">
                  <p className="upcoming text-dark">Upcoming Job Anniversary</p>
                  <hr />
                  <UpcomingJobanniversarys employees={all_employees} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
