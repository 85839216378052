import moment from "moment";
export const PAGE_TITLE = "Clients";
export const PAGE_SINGLE_TITLE = "Client";
export const LINK_URL = "clients";
export const SEARCH_TERMS = ["client_name", "meta_title"];
export const inputFields = {
  client_name: {
    type: "string",
    required: true,
    title: "Company Name",
    inputType: "text",
  },

  account_manager: {
    type: "related",
    required: true,
    title: "Account Manager",
    field: "name",
    // static: true
  },
  industry: {
    type: "related",
    required: true,
    title: "Industry",
    static: true,
  },
  about: {
    type: "string",
    required: false,
    title: "about",
  },
  source: {
    type: "related",
    required: true,
    title: "Source",
    static: true,
  },
  parent_client: {
    type: "related",
    required: false,
    title: "Parent Client",
    field: "client_name",
  },
  fax: {
    type: "string",
    required: false,
    title: "FAX",
  },
  website: {
    type: "string",
    required: false,
    title: "Website",
  },
  contact_information_divider: {
    type: "divider",
    title: "Contact Person Information",
  },
  contact_person_name: {
    type: "string",
    required: false,
    title: "Contact Person Name",
  },
  contact_person_phone: {
    type: "string",
    required: false,
    title: "Contact Person Phone",
  },
  contact_email: {
    type: "string",
    required: true,
    title: "Contact Email",
  },
  contact_designation: {
    type: "string",
    required: false,
    title: "Contact Designation",
  },
  address_information_divider: {
    type: "divider",
    title: "Address Information",
  },
  billing_street: {
    type: "string",
    required: false,
    title: "Billing Street",
  },
  billing_city: {
    type: "string",
    required: false,
    title: "Billing City",
  },
  billing_state: {
    type: "string",
    required: false,
    title: "Billing State",
  },
  billing_code: {
    type: "string",
    required: false,
    title: "Billing Code",
  },
  billing_country: {
    type: "string",
    required: false,
    title: "Billing Country",
  },
  shipping_street: {
    type: "string",
    required: false,
    title: "shipping Street",
  },
  shipping_city: {
    type: "string",
    required: false,
    title: "shipping City",
  },
  shipping_state: {
    type: "string",
    required: false,
    title: "shipping State",
  },
  shipping_code: {
    type: "string",
    required: false,
    title: "shipping Code",
  },
  shipping_country: {
    type: "string",
    required: false,
    title: "shipping Country",
  },
  attachment_information_divider: {
    type: "divider",
    title: "Attachment Information",
  },
  client_contact: {
    type: "file",
    required: false,
    title: "Client Contact",
  },
  other: {
    type: "file",
    required: false,
    title: "Other",
  },
};
export const initialValues = {
  client_name: "",
  contact_email: "",
  contact_designation: "",
  account_manager: "",
  industry: "",
  about: "",
  source: "",
  parent_client: "",
  fax: "",
  website: "",
  address_information_divider: "",
  billing_street: "",
  billing_city: "",
  billing_state: "",
  billing_code: "",
  billing_country: "",
  shipping_street: "",
  shipping_city: "",
  shipping_state: "",
  shipping_code: "",
  shipping_country: "",
  client_contact: "",
  other: "",
};

export const view_all_table = [
  {
    name: "Client Name",
    value: "client_name",
  },
  {
    name: "Contact Email",
    value: "contact_email",
  },
  {
    name: "Account Manager",
    value: "account_manager",
    related: true,
    field: "name",
    is_link: true,
    link_field: "employees",
  },
 
];

export const SIDEBAR_OPTIONS = [
  {
    id: "client_name",
    field: "client_name",
    label: "Client Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "contact_email",
    field: "contact_email",
    label: "Contact Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "account_manager",
    field: "account_manager",
    label: "Account Manager",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "industry",
    field: "industry",
    label: "Industry",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "source",
    field: "source",
    label: "Source",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "parent_client",
    field: "parent_client",
    label: "Parent Client",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "billing_city",
    field: "billing_city",
    label: "Billing City",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "billing_country",
    field: "billing_country",
    label: "Billing Country",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "shipping_city",
    field: "shipping_city",
    label: "Shipping City",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "shipping_country",
    field: "shipping_country ",
    label: "Shipping Country",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
