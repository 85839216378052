import React, { useState } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";

function DoughnutChart({ stats }) {
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (stats) {
      const filterData = stats.filter((item) => item._id);
      const labelMap = filterData.map((item) => {
        return item._id;
      });
      const valueMap = filterData.map((item) => {
        return item.count;
      });
      setLabel(labelMap);
      setValue(valueMap);
    }
  }, [stats]);

  const data = {
    labels: label,
    datasets: [
      {
        label: "Sales 2020 (M)",
        data: value,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(146, 182, 177, 1)",
          "rgba(178, 201, 171, 1)",
          "rgba(232, 221, 181, 1)",
          "rgba(87, 0, 0, 1)",
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  return <Doughnut data={data} options={options} />;
}

export default DoughnutChart;
