import moment from 'moment'
import React from 'react'
import { useAllActivitys, useUpdateActivity } from "../../shared/hooks/useActivity"

function AllActivitys() {
    const [data] = useAllActivitys()
    const { activitys, activitys_loading } = data
    const [updateData] = useUpdateActivity()
    const updateDataActivity = (id) => {
        updateData(id, { activity_status: "Completed" })

    }
    return (
        <div>
            <div className="activity-title job-info">
                <h3>Your <span className='c-red'>Activites</span></h3>
            </div>
            <hr />
            <table className='table table-stripped table-bordered'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th> Due Date
                        </th>
                        <th> Task  </th>
                        <th> Action </th>
                    </tr>
                </thead>
                <tbody>
                    {activitys && activitys.map((item, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.due_date && moment(item.due_date).format("DD-MM-YYYY")}</td>
                                <td>{item.task_name}</td>
                                <td>  <button className='btn btn-sm btn-success' onClick={() => updateDataActivity(item._id)}> <i className='fa fa-check'></i> </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>




        </div>
    )
}

export default AllActivitys