import {
  GET_CONTACTS,
  ADD_CONTACT,
  EDIT_CONTACT,
  GET_CONTACT,
  RESET_CONTACT,
  GET_ALL_CONTACTS,
  ERROR_CONTACT,
} from "../types/contact_type";

const initialState = {
  contacts: null,
  page: null,
  pages: null,
  contact: null,
  total_contacts: 0,
  loading: true,
  error: {},
  contact_message: null,
  all_contacts: null,
};

export const contact_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: payload.contacts,
        pages: payload.pages,
        page: payload.page,
        total_contacts: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CONTACTS:
      return {
        ...state,
        all_contacts: payload,
        loading: false,
        error: {},
      };

    case RESET_CONTACT:
      return {
        ...state,
        contact: null,
        loading: true,
        error: {},
      };

    case ADD_CONTACT:
      return {
        ...state,
        contact_message: payload,
        loading: false,
        error: {},
      };
    case GET_CONTACT:
      return {
        ...state,
        contact: payload,
        loading: false,
        error: {},
      };
    case EDIT_CONTACT:
      return {
        ...state,
        contact_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CONTACT:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                            