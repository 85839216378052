import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/admins_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleAdmin } from "../../shared/hooks/UseAdmin";
import AllJobPostsComponent from "../../components/jobposts/AllJobPostsComponent";
import AllCandidatesComponent from "../../components/candidates/AllCandidatesComponent";
import AllInterviewComponent from "../../components/interviews/AllInterviewsComponent";
import AllClientComponent from "../../components/clients/AllClientsComponent";
import { useAllAdmins } from "../../shared/hooks/UseAdmin";

const ViewAdmin = ({ match }) => {

  const [
    admindata,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
  ] = useAllAdmins();

  const { admins, total_admins, page, pages } = admindata;


  const [data] = useSingleAdmin(match.params.id);
  const { loading, admin } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          admin && (
            <>
              <SingleView
                data={admin}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={admin._id}
              />
              <AllJobPostsComponent
                label={
                  admin.role == "MANAGER"
                    ? "account_manager"
                    : "assigned_recruiter"
                }
                value={match.params.id}
              />
              <AllCandidatesComponent
                label={"recruiter"}
                value={match.params.id}
              />
              <AllInterviewComponent
                label={"recruiter"}
                value={match.params.id}
              />

            </>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewAdmin;
