import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addJobpost,
  getJobposts,
  getJobpost,
  editJobpost,
  deleteJobpost,
  getAllJobposts,
  getInterviewsByJobpostStats,
  getCandidatesByJobpost,
  getJobpostsStats,
} from "../../store/actions/jobpost_action";
import { SEARCH_TERMS } from "../enums/jobposts_enum";

import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { useSelectAllContact } from "../../shared/hooks/UseContact";
import { useSelectAllIndustry } from "../../shared/hooks/UseIndustry";
import { useSelectAllSkillset } from "../../shared/hooks/UseSkillset";
import { useSelectAllClient } from "../../shared/hooks/UseClient";
import { getInterviewsByJobpost } from "../../store/actions/jobpost_action";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllJobposts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobpost);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  // useEffect(() => {
  //   allQuery();
  // }, [deleteEntry, window.location.search]);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteJobpost(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getJobposts({

        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteJobpost(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get All Data
export const useAllJobpostsByCondition = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobpost);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [params, setParams] = useState(null);
  useEffect(() => {
    if (params && params.length > 0) {
      dispatch(
        getJobposts({
          pageNumber,
          params,
        })
      );
    }
  }, [params, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getJobposts({
          pageNumber,
          params,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteJobpost(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);
  useEffect(() => {
    // setPageNumber(1);
    if (params) {
      dispatch(
        getJobposts({
          pageNumber,
          params,
        })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, setParams, deleteBtnClicked];
};

export const useAllJobpostsStats = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobpost);
  const [params, setParams] = useState(null);

  useEffect(() => {
    dispatch(
      getJobpostsStats({
        params,
      })
    );
  }, [params]);

  useEffect(() => {
    dispatch(
      getJobpostsStats({
        params,
      })
    );
  }, [window.location.search]);

  return [data, setParams];
};

// Get Single Data
export const useSingleJobpost = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobpost);
  useEffect(() => {
    dispatch(getJobpost(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateJobpost = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addJobpost(data));
  };
  return [addData];
};
export const useUpdateJobpost = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editJobpost(id, data));
  };
  return [updateData];
};

export const useSelectAllJobpost = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.jobpost);
  useEffect(() => {
    dispatch(getAllJobposts({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useInterviewsByJobpost = (id) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.jobpost);
  useEffect(() => {
    dispatch(getInterviewsByJobpost(id));
  }, []);
  return [data];
};
export const useInterviewsByJobpostStats = (id) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.jobpost);
  useEffect(() => {
    dispatch(getInterviewsByJobpostStats(id));
  }, []);
  return [data];
};
export const useCandidatesByJobpost = (id) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.jobpost);
  useEffect(() => {
    dispatch(getCandidatesByJobpost(id));
  }, []);
  return [data];
};

export const useGetDropdownOptions = () => {
  const [contact] = useSelectAllContact();
  const [employee] = useSelectAllEmployee();
  const [industry] = useSelectAllIndustry();
  const [skillset] = useSelectAllSkillset();
  const [client] = useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (contact && contact.all_contacts) {
      const newData = contact.all_contacts.map((item) => {
        return { label: item.first_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, contact_name: newData });
    }
  }, [contact]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        account_manager: newData,
        assigned_recruiter: newData,
      });
    }
  }, [employee]);

  // useEffect(() => {
  //   if (employee && employee.all_employees) {
  //     const newData = employee.all_employees.map((item) => {
  //       return { label: item.name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, assigned_recruiters: newData });
  //   }
  // }, [employee]);

  useEffect(() => {
    if (skillset && skillset.all_skillsets) {
      const newData = skillset.all_skillsets.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, skill_set: newData });
    }
  }, [skillset]);

  useEffect(() => {
    if (industry && industry.all_industrys) {
      const newData = industry.all_industrys.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, industry: newData });
    }
  }, [industry]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.client_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client_name: newData });
    }
  }, [client]);

  return [dropdownOptions];
};
