import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/candidates_enum";
import SingleView from "../../components/common/SingleView";
import {
  useSingleCandidate,
  useInterviewsByCandidate,
} from "../../shared/hooks/UseCandidate";
import { Link } from "react-router-dom";
import { URI } from "../../domain/constant";
import FilterDateComponent from "../../components/common/FilterDateComponent";

const ViewCandidate = ({ match }) => {
  const [data] = useSingleCandidate(match.params.id);
  const { loading, candidate } = data;
  const [interviews_data] = useInterviewsByCandidate(match.params.id);
  const { interviews_by_candidate } = interviews_data;
  console.log(interviews_data);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />

        <div className="card">
          <div className="card-body">
            <div className="text-right">
              <Link
                to={`/interviews/add/${candidate && candidate._id}`}
                className="btn btn-md btn-primary"
              >
                Schedule Interview
              </Link>
            </div>
          </div>
        </div>
        {!loading ? (
          candidate && (
            <SingleView
              data={candidate}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={candidate._id}
            >
              <div className="col-md-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Resume</div>
                  </div>
                  <div className="card-body">
                    {candidate.resume &&
                      candidate.resume.map((item) => {
                        return (
                          <div>
                            <div>
                              <a
                                href={`${URI}/${item.resume}`}
                                target={"_blank"}
                              >
                                {" "}
                                View{" "}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Resume</div>
                  </div>
                  <div className="card-body">
                    <div className="formatted-resume">
                      {candidate && candidate.text_unformatted_resume}
                    </div>
                  </div>
                </div>
              </div>
            </SingleView>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
        {/* <div className="card">
          <div className="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Interview name</th>
                  <th scope="col">Interview Status</th>
                  <th scope="col">Candidate Name</th>
                  <th scope="col">Scheduled Interview</th>
                  <th scope="col">Interviewer</th>
                </tr>
              </thead>
              <tbody>
                {interviews_by_candidate &&
                  interviews_by_candidate.map((interview, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{interview.interview_name}</td>
                        <td>{interview.candidate_status}</td>
                        <td>
                          {interview.candidate_name &&
                            interview.candidate_name.name}
                        </td>
                        <td>{interview.from}</td>
                        <td>
                          {interview.interviewer && interview.interviewer.name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ViewCandidate;
