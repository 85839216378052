import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/clients_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleClient } from "../../shared/hooks/UseClient";
import AllJobPostsComponent from "../../components/jobposts/AllJobPostsComponent";
import AllCandidatesComponent from "../../components/candidates/AllCandidatesComponent";
import AllInterviewComponent from "../../components/interviews/AllInterviewsComponent";
import { Link } from "react-router-dom";
import moment from "moment";
import FilterDateComponent from "../../components/common/FilterDateComponent";
const ViewClient = ({ match }) => {
  const [data] = useSingleClient(match.params.id);
  const { loading, client } = data;

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        <FilterDateComponent link={`/clients/${match.params.id}/view`} />
        {/* <FilterDateComponent /> */}
        {!loading ? (
          client && (
            <>
              <SingleView
                data={client}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={client._id}
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
              />
              <AllJobPostsComponent
                label={"client_name"}
                value={match.params.id}
              />
              <AllCandidatesComponent
                label={"client_name"}
                value={match.params.id}
              />
              <AllInterviewComponent
                label={"client"}
                value={match.params.id} />
            </>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewClient;
