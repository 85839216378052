import {
  GET_SKILLSETS,
  ADD_SKILLSET,
  EDIT_SKILLSET,
  GET_SKILLSET,
  RESET_SKILLSET,
  GET_ALL_SKILLSETS,
  ERROR_SKILLSET,
} from "../types/skillset_type";

const initialState = {
  skillsets: null,
  page: null,
  pages: null,
  skillset: null,
  total_skillsets: 0,
  loading: true,
  error: {},
  skillset_message: null,
  all_skillsets: null,
};

export const skillset_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SKILLSETS:
      return {
        ...state,
        skillsets: payload.skills,
        pages: payload.pages,
        page: payload.page,
        total_skillsets: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_SKILLSETS:
      return {
        ...state,
        all_skillsets: payload,
        loading: false,
        error: {},
      };

    case RESET_SKILLSET:
      return {
        ...state,
        skillset: null,
        loading: true,
        error: {},
      };

    case ADD_SKILLSET:
      return {
        ...state,
        skillset_message: payload,
        loading: false,
        error: {},
      };
    case GET_SKILLSET:
      return {
        ...state,
        skillset: payload,
        loading: false,
        error: {},
      };
    case EDIT_SKILLSET:
      return {
        ...state,
        skillset_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_SKILLSET:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
