import api from "../../domain/api";
import {
  GET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  EDIT_NOTIFICATION,
  GET_NOTIFICATION,
  RESET_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
  ERROR_NOTIFICATION,
} from "../types/notification_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const getAllNotifications =
  () =>
    async (dispatch) => {
      try {
        const { data } = await api.get(
          `/notifications/all`
        );

        dispatch({
          type: GET_ALL_NOTIFICATIONS,
          payload: data,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_NOTIFICATION });
};
