import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData, upload } from "../../shared/upload";
import {
    initialValues,
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/documents_enum";
import { useUpdateDocument, useSingleDocument, useGetDropdownOptions } from "../../shared/hooks/UseDocument";


const EditDocument = ({ match }) => {
    let history = useHistory();
    const [data] = useSingleDocument(match.params.id);
    const [updateData] = useUpdateDocument();
    const { loading, document } = data;
    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);

    const submitFormClicked = async (values) => {
        // if (featuredImage || gallery) {
        //   const images = await upload(featuredImage, gallery);
        //   if (images) {
        //     if (images.featured_image) {
        //       values.featured_image = images.featured_image;
        //     }
        //     if (images.gallery_images) {
        //       values.gallery = images.gallery_images;
        //     }
        //   }
        // }
        // await updateData(document._id, values);

        // history.push(`/${LINK_URL}/${document._id}/view`);

        const data = await convertToFormData({ values, featuredImage });
        await updateData(document._id, data);

        history.goBack();
    };

    const [dropdownOptions] = useGetDropdownOptions();

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={`Edit ${PAGE_SINGLE_TITLE}`}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="Edit"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        {!loading ? (
                            document && (
                                <div>
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {document[Object.keys(inputFields)[0]]} -{" "}
                                                <span>Edit</span>{" "}
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link
                                                    to={`/${LINK_URL}`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                                                </Link>
                                                <Link
                                                    to={`/${LINK_URL}/${document._id}/view`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <AddForm
                                        data={document}
                                        edit={true}
                                        featuredImage={featuredImage}
                                        gallery={gallery}
                                        setFeaturedImage={setFeaturedImage}
                                        setGallery={setGallery}
                                        submitForm={submitFormClicked}
                                        inputFields={inputFields}
                                        initialValues={initialValues}
                                        dropdown_options={dropdownOptions}
                                    />
                                </div>
                            )
                        ) : (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditDocument;
