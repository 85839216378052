import api from "../../domain/api";
import {
  GET_HOLIDAYS_STATED,
  GET_HOLIDAYS,
  GET_HOLIDAYS_ENDED,
  ADD_HOLIDAY_STATED,
  ADD_HOLIDAY,
  ADD_HOLIDAY_ENDED,
  EDIT_HOLIDAY_STATED,
  EDIT_HOLIDAY,
  EDIT_HOLIDAY_ENDED,
  GET_HOLIDAY_STATED,
  GET_HOLIDAY,
  GET_HOLIDAY_ENDED,
  GET_ALL_HOLIDAYS_STATED,
  GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS_ENDED,
} from "../types/holiday_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addHoliday = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_HOLIDAY_STATED,
    });
    const { data } = await api.post(`/holidays`, formData);
    dispatch({
      type: ADD_HOLIDAY,
      payload: data,
    });
    dispatch({
      type: ADD_HOLIDAY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_HOLIDAY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getHolidays =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_HOLIDAYS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/holidays?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_HOLIDAYS,
        payload: data,
      });
      dispatch({
        type: GET_HOLIDAYS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_HOLIDAYS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getHoliday = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HOLIDAY_STATED,
    });
    const { data } = await api.get(`/holidays/${id}`);

    dispatch({
      type: GET_HOLIDAY,
      payload: data,
    });
    dispatch({
      type: GET_HOLIDAY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_HOLIDAY_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editHoliday = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_HOLIDAY_STATED,
    });
    const { data } = await api.put(`/holidays/${id}`, formData);
    dispatch({
      type: EDIT_HOLIDAY,
      payload: data,
    });
    dispatch({
      type: EDIT_HOLIDAY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_HOLIDAY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteHoliday = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/holidays/${id}`);
    dispatch(setAlert("Holiday Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllHolidays = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_HOLIDAYS_STATED,
    });
    const { data } = await api.get(`/holidays/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_HOLIDAYS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_HOLIDAYS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_HOLIDAYS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
