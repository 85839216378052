export const GET_CANDIDATES = "GET_CANDIDATES";
export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const EDIT_CANDIDATE = "EDIT_CANDIDATE";
export const GET_CANDIDATE = "GET_CANDIDATE";
export const RESET_CANDIDATE = "RESET_CANDIDATE";
export const ERROR_CANDIDATE = "ERROR_CANDIDATE";
export const GET_ALL_CANDIDATES = "GET_ALL_CANDIDATES";
export const INTERVIEWS_BY_CANDIDATE = "INTERVIEWS_BY_JOBPOST";

export const CANDIDATE_STATS_STARTED = "CANDIDATE_STATS_STARTED";
export const CANDIDATE_STATS = "CANDIDATE_STATS";
export const CANDIDATE_STATS_ENDED = "CANDIDATE_STATS_ENDED";
