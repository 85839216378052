import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDailyReports,
  getClosedReports,
} from "../../store/actions/report_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get Single Data
export const useGetDailyReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(getDailyReports());
  }, [window.location.search]);

  return [data];
};
export const useCloseReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(getClosedReports());
  }, [window.location.search]);

  return [data];
};
