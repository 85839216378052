import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAdmin,
  getAdmins,
  getAdmin,
  editAdmin,
  deleteAdmin,
  getAllAdmins,
} from "../../store/actions/admin_action";
import { SEARCH_TERMS } from "../enums/admins_enum";
// import { useSelectAllAdmin } from "../hooks/UseAdmin"

// Get All Data
export const useAllAdmins = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admin);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [q, setQ] = useState(null);
  const [term, setTerm] = useState(null);
  const [termField, setTermField] = useState(SEARCH_TERMS[0]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  useEffect(() => {
    async function allQuery() {
      if (deleteEntry) {
        await dispatch(deleteAdmin(deleteEntry));
      }
      if (term && term.length > 0) {
        setQ(term);
        dispatch(
          getAdmins({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
          })
        );
      } else {
        dispatch(
          getAdmins({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
          })
        );
      }
    }
    allQuery();
  }, [deleteEntry, term, pageNumber, dateFrom, dateTo]);
  useEffect(() => {
    setPageNumber(1);
  }, [term, dateTo, dateFrom]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };
  const resetFilter = () => {
    setPageNumber(1);
    setQ(null);
    setTerm("");
    setDateFrom("");
    setDateTo("");
    dispatch(getAdmins({}));
  };

  return [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
  ];
};
// Get Single Data
export const useSingleAdmin = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getAdmin(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateAdmin = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addAdmin(data));
  };
  return [addData];
};
export const useUpdateAdmin = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editAdmin(id, data));
  };
  return [updateData];
};
export const useSelectAllAdmin = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getAllAdmins({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {

  const [admin, setAdminSearchField, setAdminSearchValue] =
  useSelectAllAdmin();
  console.log("Admin",admin);
  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (admin && admin.all_admins) {
      let filterData = admin.all_admins.filter((item)=>item.role == "MANAGER")
      const newData = filterData.map((item) => {
        
        return { label: item.name, value: item._id, role: item.role };
      });

      

      setDropdownOptions({ ...dropdownOptions, manager: newData });
    }
  }, [admin]);

  const loadOptions = async (inputValue, callback, field) => {
   
    if (field == "manager") {
      await setAdminSearchField("manager");
      await setAdminSearchValue(inputValue);
      callback(dropdownOptions.manager);
    }
  };

  return [dropdownOptions, loadOptions];
}


