import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Formik, Form } from 'formik'
import { TextArea, TextInput, SelectBox } from '../../components/Form/Form'
import * as Yup from 'yup'
import { useCreateActivity } from "../.././shared/hooks/useActivity"
import { convertToFormData } from "../../shared/upload";

function AddActivityForm({ page }) {
    const [data, addData] = useCreateActivity();
    const submitFormClicked = async (values) => {

        // const data = await convertToFormData({ values });
        await addData(values);
        //   window.location.href = `/thankyou`;
    };
    return (
        <div>
            <Formik
                initialValues={{
                    task_name: "",
                    due_date: "",

                }}
                validationSchema={Yup.object({
                    task_name: Yup.string().required("Required"),
                    due_date: Yup.string().required("Required"),

                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    values.activity_status = "Pending"
                    await submitFormClicked(values);
                    console.log("Form submit ho gaya hai");
                    resetForm();
                    setSubmitting(false);
                }}
            >
                {(formik) => {
                    console.log(formik);
                    return (
                        <Form>
                            <div className="row">
                                <div className="activity-form-title">
                                    <h3>Add Quick <span style={{ color: "#c73211" }}>Task:</span> </h3>
                                </div>
                                <div className="col-md-12">
                                    <TextInput
                                        label="Task Name"
                                        name="task_name"
                                        type="text"
                                        placeholder="Enter Task Name"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextInput
                                        label="Due Date"
                                        name="due_date"
                                        type="date"
                                        placeholder="Enter Due Date"
                                    />
                                </div>

                                <div className="col-lg-12" style={{ textAlign: "center" }}>
                                    <button
                                        className="button-31 mt-10"
                                        type="submit"
                                        id="appointment-submit"
                                        name="submit"
                                    >
                                        Add Task
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}

export default AddActivityForm