import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import AddBtn from "../../components/common/AddBtn";
import {
  SEARCH_TERMS,
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/candidatestatuss_enum";
import DataTable from "../../components/common/DataTable";
import { useAllCandidatestatuss } from "../../shared/hooks/UseCandidatestatus";
import SidebarFilter from "../../components/common/SidebarFilter";

const AllCandidatestatuss = ({ }) => {
  const [
    data, setPageNumber, deleteBtnClicked
  ] = useAllCandidatestatuss();
  const { loading, candidatestatuss, total_candidatestatuss, page, pages } =
    data;

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter SIDEBAR_OPTIONS={SIDEBAR_OPTIONS} />
            )}
            <div className="col-lg-9">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_candidatestatuss} records found
                 
                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={candidatestatuss}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    />
                    <Pagination
                      data={candidatestatuss}
                      page={page}
                      pages={pages}
                      count={total_candidatestatuss}
                      setPage={setPageNumber}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCandidatestatuss;
