import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/employees_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleEmployee } from "../../shared/hooks/UseEmployee";
import AllJobPostsComponent from "../../components/jobposts/AllJobPostsComponent";
import AllCandidatesComponent from "../../components/candidates/AllCandidatesComponent";
import AllInterviewComponent from "../../components/interviews/AllInterviewsComponent";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { Link } from "react-router-dom";
import { useSelectAllDashboard } from "../../shared/hooks/UseDashboard";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee"


const ViewEmployee = ({ match }) => {
  const [data] = useSingleEmployee(match.params.id);
  const { loading, employee, total_jobpost_closed_by_recruiter } = data;
  const [dashboardData, fromDate, setFromDate, toDate, setToDate] =
    useSelectAllDashboard();
  const { all_dashboards, all_dashboards_loading } = dashboardData;

  const [employeeData] = useSelectAllEmployee();
  const { all_employees } = employeeData;

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        <FilterDateComponent link={`/employees/${match.params.id}/view`} />

        {!loading ? (
          employee && (
            <>
              <SingleView
                data={employee}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={employee._id}
              >
                <div className="col-md-3">
                  <h4>Requirements</h4>
                  <Link
                    to={`/jobPosts?exact[job_opening_status]=Closed%20By%20Accord&${window.location.search &&
                      window.location.search.length > 0
                      ? window.location.search.split("?")[1]
                      : ""
                      }`}
                  >
                    <div className="dashboard-stat pending">
                      <div className="report-title">Closed By {employee.name} </div>
                      <div className="report-stat">{total_jobpost_closed_by_recruiter ? total_jobpost_closed_by_recruiter : 0}</div>
                    </div>
                  </Link>

                </div>
              </SingleView>


              <AllJobPostsComponent
                label={
                  employee.role == "MANAGER"
                    ? "assigned_recruiter"
                    : "assigned_recruiter"
                }
                value={match.params.id}
              />
              <AllCandidatesComponent
                label={"recruiter"}
                value={match.params.id}
              />
              <AllInterviewComponent
                label={"recruiter"}
                value={match.params.id}
              />

            </>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewEmployee;
