import api from "../../domain/api";
import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  GET_EMPLOYEE,
  RESET_EMPLOYEE,
  GET_ALL_EMPLOYEES,
  ERROR_EMPLOYEE,
} from "../types/employee_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addEmployee = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/employees`, formData);
    dispatch({
      type: ADD_EMPLOYEE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getEmployees =
  ({ pageNumber = "" }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: RESET_EMPLOYEE,
        });
        const queryParams = qs.parse(window.location.search.replace("?", ""));
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true, // prettify url
        });
        const { data } = await api.get(
          `/employees?&pageNumber=${pageNumber}&${query}`
        );

        dispatch({
          type: GET_EMPLOYEES,
          payload: data,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const getEmployee = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_EMPLOYEE,
    });
    const queryParams = qs.parse(window.location.search.replace("?", ""));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    const { data } = await api.get(`/employees/${id}?&${query}`);

    dispatch({
      type: GET_EMPLOYEE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editEmployee = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/employees/${id}`, formData);
    dispatch({
      type: EDIT_EMPLOYEE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteEmployee = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/employees/${id}`);
    dispatch(setAlert("Employee Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllEmployees =
  ({ term, value }) =>
    async (dispatch) => {
      try {
        const { data } = await api.get(
          `/employees/all?term=${term}&value=${value}`
        );

        dispatch({
          type: GET_ALL_EMPLOYEES,
          payload: data,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_EMPLOYEE });
};
