import api from "../../domain/api";
import {
  GET_CANDIDATESTATUSS,
  ADD_CANDIDATESTATUS,
  EDIT_CANDIDATESTATUS,
  GET_CANDIDATESTATUS,
  RESET_CANDIDATESTATUS,
  GET_ALL_CANDIDATESTATUSS,
  ERROR_CANDIDATESTATUS,
} from "../types/candidatestatus_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addCandidatestatus = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/candidatestatuss`, formData);
    dispatch({
      type: ADD_CANDIDATESTATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCandidatestatuss =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CANDIDATESTATUS,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      const { data } = await api.get(
        `/candidatestatuss?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_CANDIDATESTATUSS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCandidatestatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CANDIDATESTATUS,
    });
    const { data } = await api.get(`/candidatestatuss/${id}`);

    dispatch({
      type: GET_CANDIDATESTATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCandidatestatus = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/candidatestatuss/${id}`, formData);
    dispatch({
      type: EDIT_CANDIDATESTATUS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCandidatestatus = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/candidatestatuss/${id}`);
    dispatch(setAlert("Candidatestatus Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCandidatestatuss =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/candidatestatuss/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_CANDIDATESTATUSS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CANDIDATESTATUS });
};
