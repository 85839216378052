import React from 'react';
import Header from '../../components/template/Header';
import BreadCrumb from '../../components/template/BreadCrumb';
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/employees_enum';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import ExportComponent from '../../components/common/ExportComponent';
import { UseDataForExcel, UseFilter } from '../../shared/hooks/UseExcel';
import { useGetDailyReport } from '../../shared/hooks/UseReport';
import { useState, useEffect } from 'react';

function JobPostCandidate({ match }) {
  const [exportXLSXData] = UseFilter();
  const [reportData] = useGetDailyReport();
  const { daily_reports } = reportData;
  //   const [convertToReadable, exportData] = UseDataForExcel();
  const [dataToExport, setDataToExport] = useState(null);

  useEffect(() => {
    if (daily_reports) {
      let newData = daily_reports.map((item, index) => {
        let newItem = {};
        newItem['SR No'] = index + 1;
        newItem['Total'] = item.count;
        newItem['Recruiter'] = item.recruiter ? item.recruiter.name : '';
      });
      setDataToExport(newData);
    }
  }, [daily_reports]);

  const handleOnExport = () => {
    exportXLSXData(dataToExport, 'Entries By Recruiter', 'Daily Report');
  };

  return (
    <div>
      <div>
        <div className='pace-done'>
          <div>
            <Header />
            <BreadCrumb
              title='Recruiters Report'
              mainLinkTitle='Recruiter'
              mainLinkUrl='/employees'
              activeLink={PAGE_TITLE}
            />

            <ExportComponent handleOnExport={handleOnExport} />

            <FilterDateComponent link={`/reports/entry-by-recruiter`} />

            <section className='employee-report-section'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-7'>
                    <div className='employee-reports-content'>
                      <div className='employee-reports-title'>
                        <p>Entry By Recruiters Report:</p>
                      </div>

                      <div className='employee-report-table'>
                        <table class='table table-bordered'>
                          <thead>
                            <tr>
                              <th scope='col'>#</th>
                              <th scope='col'>Profile</th>
                              <th scope='col'>Client</th>
                              <th scope='col'>No. of positions</th>
                              <th scope='col'>CTC Budget (LPA)</th>
                              <th scope='col'>Account Manager</th>
                              <th scope='col'>Req. Recd Date</th>
                              <th scope='col'>Allocation Status</th>
                              <th scope='col'>Allocated To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {daily_reports &&
                              daily_reports.map((item, index) => {
                                return (
                                  <tr>
                                    <th scope='row'>{index + 1}</th>
                                    <td>
                                      {item.recruiter && item.recruiter.name}
                                    </td>
                                    <td> {item.count} </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPostCandidate;
