import {
  GET_USERROLES,
  ADD_USERROLE,
  EDIT_USERROLE,
  GET_USERROLE,
  RESET_USERROLE,
  GET_ALL_USERROLES,
  ERROR_USERROLE,
} from "../types/userrole_type";

const initialState = {
  userroles: null,
  page: null,
  pages: null,
  userrole: null,
  total_userroles: 0,
  loading: true,
  error: {},
  userrole_message: null,
  all_userroles: null,
};

export const userrole_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USERROLES:
      return {
        ...state,
        userroles: payload.userroles,
        pages: payload.pages,
        page: payload.page,
        total_userroles: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_USERROLES:
      return {
        ...state,
        all_userroles: payload,
        loading: false,
        error: {},
      };

    case RESET_USERROLE:
      return {
        ...state,
        userrole: null,
        loading: true,
        error: {},
      };

    case ADD_USERROLE:
      return {
        ...state,
        userrole_message: payload,
        loading: false,
        error: {},
      };
    case GET_USERROLE:
      return {
        ...state,
        userrole: payload,
        loading: false,
        error: {},
      };
    case EDIT_USERROLE:
      return {
        ...state,
        userrole_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_USERROLE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                    