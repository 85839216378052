import {
  GET_INTERVIEWS,
  ADD_INTERVIEW,
  EDIT_INTERVIEW,
  GET_INTERVIEW,
  RESET_INTERVIEW,
  GET_ALL_INTERVIEWS,
  ERROR_INTERVIEW,
  GET_ALL_INTERVIEWS_BY_JOB_POST,
  INTERVIEW_STATS_STARTED,
  INTERVIEW_STATS,
  INTERVIEW_STATS_ENDED,
} from "../types/interview_type";

const initialState = {
  interviews: null,
  page: null,
  pages: null,
  interview: null,
  total_interviews: 0,
  loading: true,
  error: {},
  interview_message: null,
  all_interviews: null,
  interviews_stats_loading: true,
  interviews_stats: null,
};

export const interview_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INTERVIEWS:
      return {
        ...state,
        interviews: payload.interviews,
        pages: payload.pages,
        page: payload.page,
        total_interviews: payload.count,
        loading: false,
        error: {},
      };
    case INTERVIEW_STATS_STARTED:
      return {
        ...state,
        interviews_stats: null,
        interviews_stats_loading: true,
      };
    case INTERVIEW_STATS:
      return {
        ...state,
        interviews_stats: payload,
      };
    case INTERVIEW_STATS_ENDED:
      return {
        ...state,
        interviews_stats_loading: false,
      };
    case GET_ALL_INTERVIEWS:
      return {
        ...state,
        all_interviews: payload,
        loading: false,
        error: {},
      };
    case GET_ALL_INTERVIEWS_BY_JOB_POST:
      return {
        ...state,
        all_interviews: payload,
        loading: false,
        error: {},
      };

    case RESET_INTERVIEW:
      return {
        ...state,
        interview: null,
        loading: true,
        error: {},
      };

    case ADD_INTERVIEW:
      return {
        ...state,
        interview_message: payload,
        loading: false,
        error: {},
      };
    case GET_INTERVIEW:
      return {
        ...state,
        interview: payload,
        loading: false,
        error: {},
      };
    case EDIT_INTERVIEW:
      return {
        ...state,
        interview_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_INTERVIEW:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
