import moment from "moment";

export const PAGE_TITLE = "Categories";
export const PAGE_SINGLE_TITLE = "Category";
export const LINK_URL = "categories";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
    message: {
        type: "string",
        required: true,
        title: "Message",
    },
    is_opened: {
        type: "boolean",
        required: false,
        title: "Is Opened",
        default: false,
    },
    date: {
        type: "string",
        required: false,
        title: "Date",
        inputType: "date",
        inputType: "datetime-local"
    },
    notification_type: {
        type: "string",
        required: false,
        title: "Notofication Type",
    },

};
export const initialValues = {
    message: "",
    is_opened: "",
    date: moment().format("YYYY-MM-DD"),
    notification_type: false,

};

export const view_all_table = [
    { name: "Message", value: "message" },
    { name: "Notification Type", value: "notification_type" },
];
