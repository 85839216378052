import api from "../../domain/api";
import {
  GET_CVS,
  ADD_CV,
  EDIT_CV,
  GET_CV,
  RESET_CV,
  GET_ALL_CVS,
  ERROR_CV,
} from "../types/cv_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addCv = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/cvs`, formData);
    dispatch({
      type: ADD_CV,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCvs =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CV,
      });
      const { data } = await api.get(
        `/cvs?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_CVS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCv = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CV,
    });
    const { data } = await api.get(`/cvs/${id}`);

    dispatch({
      type: GET_CV,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCv = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/cvs/${id}`, formData);
    dispatch({
      type: EDIT_CV,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCv = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/cvs/${id}`);
    dispatch(setAlert("Cv Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCvs =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`/cvs/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_CVS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CV });
};
