import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addClient,
  getClients,
  getClient,
  editClient,
  deleteClient,
  getAllClients,
  getClientsStats,
} from "../../store/actions/client_action";
import _debounce from "lodash/debounce";
import { useSelectAllIndustry } from "./UseIndustry";
import { useSelectAllSource } from "./UseSource";
import { useSelectAllEmployee } from "./UseEmployee";

// Get All Data
export const useAllClients = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  console.log("ID TO DELETE", deleteEntry);
  // useEffect(() => {
  //   allQuery();
  // }, [deleteEntry, window.location.search]);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteClient(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getClients({

        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteClient(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get All Data
export const useAllClientsByCondition = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [params, setParams] = useState(null);

  useEffect(() => {
    if (params && params.length > 0) {
      dispatch(
        getClients({
          pageNumber,
          params,
        })
      );
    }
  }, [params, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getClients({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      // dispatch(deleteClient(deleteEntry));
      // allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    // setPageNumber(1);
    if (params) {
      dispatch(
        getClients({
          pageNumber,
          params,
        })
      );
    }
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, setParams, deleteBtnClicked];
};

export const useAllClientsStats = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  const [params, setParams] = useState(null);

  useEffect(() => {
    dispatch(
      getClientsStats({
        params,
      })
    );
  }, [params]);

  useEffect(() => {
    dispatch(
      getClientsStats({
        params,
      })
    );
  }, [window.location.search]);

  return [data, setParams];
};


// Get Single Data
export const useSingleClient = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getClient(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateClient = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addClient(data));
  };
  return [addData];
};
export const useUpdateClient = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editClient(id, data));
  };
  return [updateData];
};

export const useSelectAllClient = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getAllClients({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [industry, setIndustrySearchField, setIndustrySearchValue] =
    useSelectAllIndustry();
  const [source, setSourceSearchField, setSourceSearchValue] =
    useSelectAllSource();
  const [employee, setEmployeeSearchField, setSearchFieldValue] =
    useSelectAllEmployee();
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();
  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (industry && industry.all_industrys) {
      const newData = industry.all_industrys.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, industry: newData });
    }
  }, [industry]);

  useEffect(() => {
    if (source && source.all_sources) {
      const newData = source.all_sources.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, source: newData });
    }
  }, [source]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, account_manager: newData });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.client_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, parent_client: newData });
    }
  }, [client]);

  const loadOptions = async (inputValue, callback, field) => {
    if (field == "industry") {
      await setIndustrySearchField("name");
      await setIndustrySearchValue(inputValue);
      callback(dropdownOptions.industry);
    }
    if (field == "account_manager") {
      await setEmployeeSearchField("name");
      await setSearchFieldValue(inputValue);
      callback(dropdownOptions.account_manager);
    }
    if (field == "source") {
      await setSourceSearchField("name");
      await setSourceSearchValue(inputValue);
      callback(dropdownOptions.source);
    }
    if (field == "parent_client") {
      await setClientSearchField("client_name");
      await setClientSearchValue(inputValue);
      callback(dropdownOptions.parent_client);
    }
  };
  return [dropdownOptions, loadOptions];
};
