import {
    GET_ACTIVITYS_STATED,
    GET_ACTIVITYS,
    GET_ACTIVITYS_ENDED,
    ADD_ACTIVITY_STATED,
    ADD_ACTIVITY,
    ADD_ACTIVITY_ENDED,
    EDIT_ACTIVITY_STATED,
    EDIT_ACTIVITY,
    EDIT_ACTIVITY_ENDED,
    GET_ACTIVITY_STATED,
    GET_ACTIVITY,
    GET_ACTIVITY_ENDED,
    GET_ALL_ACTIVITYS_STATED,
    GET_ALL_ACTIVITYS,
    GET_ALL_ACTIVITYS_ENDED
} from "../types/activity_type";

const initialState = {
    activitys_loading: true,
    activitys: null,
    page: null,
    pages: null,
    total_activitys: 0,

    activity: null,
    activity_loading: null,

    loading: true,

    activity_message: null,
    all_activitys: null,
    all_activitys_loading: null,
    add_activity_loading: true,
    edit_activity_loading: true
};

export const activity_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ACTIVITYS_STATED:
            return {
                ...state,
                activitys: null,
                pages: null,
                page: null,
                total_activitys: 0,
                activitys_loading: true
            };
        case GET_ACTIVITYS:
            return {
                ...state,
                activitys: payload.activitys,
                pages: payload.pages,
                page: payload.page,
                total_activitys: payload.count
            };
        case GET_ACTIVITYS_ENDED:
            return {
                ...state,
                activitys_loading: false
            };
        case GET_ALL_ACTIVITYS_STATED:
            return {
                ...state,
                all_activitys_loading: true,
                all_activitys: null
            };
        case GET_ALL_ACTIVITYS:
            return {
                ...state,
                all_activitys: payload
            };
        case GET_ALL_ACTIVITYS_ENDED:
            return {
                ...state,
                all_activitys_loading: false
            };

        case ADD_ACTIVITY_STATED:
            return {
                ...state,
                activity_message: null,
                add_activity_loading: true
            };
        case ADD_ACTIVITY:
            return {
                ...state,
                activity_message: payload
            };
        case ADD_ACTIVITY_ENDED:
            return {
                ...state,
                add_activity_loading: false
            };
        case GET_ACTIVITY_STATED:
            return {
                ...state,
                activity: null,
                activity_loading: true
            };
        case GET_ACTIVITY:
            return {
                ...state,
                activity: payload
            };
        case GET_ACTIVITY_ENDED:
            return {
                ...state,
                activity_loading: false
            };
        case EDIT_ACTIVITY_STATED:
            return {
                ...state,
                activity_message: null,
                edit_activity_loading: true
            };
        case EDIT_ACTIVITY:
            return {
                ...state,
                activity_message: payload
            };
        case EDIT_ACTIVITY_ENDED:
            return {
                ...state,
                edit_activity_loading: false
            };

        default:
            return state;
    }
};
