import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";

import { upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/interviews_enum";
import {
  useUpdateInterview,
  useSingleInterview,
} from "../../shared/hooks/UseInterview";
import { useSelectAllJobpost } from "../../shared/hooks/UseJobpost";
import { useSelectAllInterview } from "../../shared/hooks/UseInterview";
import { useSelectAllCandidate } from "../../shared/hooks/UseCandidate";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";

const EditInterview = ({ match }) => {
  let history = useHistory();
  const [data] = useSingleInterview(match.params.id);
  const [updateData] = useUpdateInterview();
  const { loading, interview } = data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const [jobpost] = useSelectAllJobpost();
  // const [interview] = useSelectAllInterview();
  const [candidate] = useSelectAllCandidate();
  const [employee] = useSelectAllEmployee();
  const [candidatestatus] = useSelectAllCandidatestatus();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    // if (featuredImage || gallery) {
    //   const images = await upload(featuredImage, gallery);
    //   if (images) {
    //     if (images.featured_image) {
    //       values.featured_image = images.featured_image;
    //     }
    //     if (images.gallery_images) {
    //       values.gallery = images.gallery_images;
    //     }
    //   }
    // }
    // await updateData(interview._id, values);

    // history.push(`/${LINK_URL}/${interview._id}/view`);
    // const filteredData =
    //   all_jobposts &&
    //   all_jobposts.filter((item) => item._id == values.posting_title);
    // console.log(filteredData);
    // if (filteredData && filteredData.length > 0) {
    //   values.client = filteredData[0].client_name
    //     ? filteredData[0].client_name._id
    //     : "";
    // }

    // values.candidate_name = match.params.candidate;
    const data = await convertToFormData({ values, featuredImage });
    // console.log("DATA", data);
    await updateData(match.params.id, data);
    history.goBack();
  };

  useEffect(() => {
    if (candidate && candidate.all_candidates) {
      const newData = candidate.all_candidates.map((item) => {
        return { label: item.first_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_name: newData });
    }
  }, [candidate]);

  useEffect(() => {
    if (candidatestatus && candidatestatus.all_candidatestatuss) {
      const newData = candidatestatus.all_candidatestatuss.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_status: newData });
    }
  }, [candidatestatus]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, recruiter: newData });
    }
  }, [employee]);
  useEffect(() => {
    if (jobpost && jobpost.all_jobposts) {
      const newData = jobpost.all_jobposts.map((item) => {
        return { label: item.posting_title, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, posting_title: newData });
    }
  }, [jobpost]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              interview && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {interview[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${interview._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={interview}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInterview;
