import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/documents_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleDocument } from "../../shared/hooks/UseDocument";
const ViewDocument = ({ match }) => {
    const [data] = useSingleDocument(match.params.id);
    const { loading, document } = data;

    console.log("Document -", document);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={PAGE_SINGLE_TITLE}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="View"
                />
                {!loading ? (
                    document && (
                        <SingleView
                            data={document}
                            inputFields={inputFields}
                            label={PAGE_SINGLE_TITLE}
                            link={LINK_URL}
                            id={document._id}
                            hideGallery={true}
                        />
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewDocument;
