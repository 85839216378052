import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData, upload } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/interviews_enum";
import { useEffect } from "react";
import {
  useCreateInterview,
  useAllInterviews,
  useGetDropdownOptions,
} from "../../shared/hooks/UseInterview";
import {
  useAllJobposts,
  useSelectAllJobpost,
} from "../../shared/hooks/UseJobpost";
import {
  useSelectAllCandidate,
  useAllCandidates,
} from "../../shared/hooks/UseCandidate";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";

const AddInterview = ({ match }) => {
  let history = useHistory();
  const [addData] = useCreateInterview();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [candidatestatus] = useSelectAllCandidatestatus();
  const [employee] = useSelectAllEmployee();

  const [dropdownOptions, setDropdownOptions] = useState({});
  const [clientId, setClientId] = useState();
  const [data] = useAllJobposts();
  const { all_jobposts } = data;

  const submitFormClicked = async (values) => {
    values.candidate = match.params.candidate;
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.goBack();
    // console.log("Values here", values);
  };
  const [jobpost] = useSelectAllJobpost();

  useEffect(() => {
    if (jobpost && jobpost.all_jobposts) {
      const newData = jobpost.all_jobposts.map((item) => {
        return {
          label: `${item.posting_title}-(${item.client_name && item.client_name.client_name
            })`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, posting_title: newData });
    }
  }, [jobpost]);
  console.log(jobpost.all_jobposts);

  const [candidate] = useAllCandidates();

  const { loading, candidates } = candidate;
  console.log("Candidate", candidate);

  useEffect(() => {
    if (candidatestatus && candidatestatus.all_candidatestatuss) {
      const newData = candidatestatus.all_candidatestatuss.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_status: newData });
    }
  }, [candidatestatus]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, recruiter: newData });
    }
  }, [employee]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInterview;
