import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCv,
  getCvs,
  getCv,
  editCv,
  deleteCv,
  getAllCvs,
} from "../../store/actions/cv_action";
import { SEARCH_TERMS } from "../enums/cvs_enum";
// Get All Data
export const useAllCvs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cv);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [q, setQ] = useState(null);
  const [term, setTerm] = useState(null);
  const [termField, setTermField] = useState(SEARCH_TERMS[0]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  useEffect(() => {
    async function allQuery() {
      const params = new URLSearchParams(window.location.search);

      const paramsObj = Array.from(params.keys()).reduce(
        (acc, val) => ({ ...acc, [val]: params.get(val) }),
        {}
      );
      let newQParams = ``;
      Object.keys(paramsObj) &&
        Object.keys(paramsObj).map((item, index) => {
          if (item.includes("_select")) {
            if (item.split("_select") && item.split("_select")[0]) {
              newQParams += `&select[${item.split("_select")[0]}]=${
                paramsObj[item]
              }`;
            }
          } else {
            newQParams += `&exact[${item}]=${paramsObj[item]}`;
          }
        });
      if (deleteEntry) {
        await dispatch(deleteCv(deleteEntry));
      }
      if (term && term.length > 0) {
        setQ(term);
        dispatch(
          getCvs({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
            queryParams: newQParams,
          })
        );
      } else {
        dispatch(
          getCvs({
            termField: termField ? termField : termField,
            term: q ? q : "",
            pageNumber,
            start_from: dateFrom,
            start_to: dateTo,
            queryParams: newQParams,
          })
        );
      }
    }
    allQuery();
  }, [deleteEntry, term, pageNumber, dateFrom, dateTo, window.location.search]);
  useEffect(() => {
    setPageNumber(1);
  }, [term, dateTo, dateFrom]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };
  const resetFilter = () => {
    setPageNumber(1);
    setQ(null);
    setTerm("");
    setDateFrom("");
    setDateTo("");
    dispatch(getCvs({}));
  };

  return [
    data,
    pageNumber,
    setPageNumber,
    q,
    term,
    setTerm,
    termField,
    setTermField,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
    resetFilter,
  ];
};

// Get Single Data
export const useSingleCv = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cv);
  useEffect(() => {
    dispatch(getCv(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCv = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addCv(data));
  };
  return [addData];
};
export const useUpdateCv = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editCv(id, data));
  };
  return [updateData];
};

export const useSelectAllCv = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cv);
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    dispatch(getAllCvs({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
