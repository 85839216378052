import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAttendance,
  getTodayAttendance,
  getMonthlyAttendance,
  getTodayAttendanceEmployee,
  getMonthlyAttendanceEmployee,
  editAttendance,
  deleteAttendance,
  handleErrorLocal,
  getAttendances,
} from "../../store/actions/attendance_action";
import { SEARCH_TERMS } from "../enums/attendances_enum";
import { useSelectAllEmployee } from "./UseEmployee";



// Get All Data
// Get All Data
export const useAllAttendances = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendance);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  console.log("ID TO DELETE", deleteEntry);
  // useEffect(() => {
  //   allQuery();
  // }, [deleteEntry, window.location.search]);
  useEffect(() => {
    // if (deleteEntry) {
    //   dispatch(deleteCandidate(deleteEntry));
    // }
    dispatch(
      getAttendances({

      })
    );
  }, [deleteEntry, pageNumber, window.location.search]);


  // useEffect(() => {
  //   if (deleteEntry) {
  //     dispatch(deleteCandidate(deleteEntry));
  //     allQuery();
  //   }
  // }, [deleteEntry]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};
// Get Single Data
// export const useSingleAttendance = (id) => {
//     const dispatch = useDispatch();
//     const data = useSelector((state) => state.attendance);
//     useEffect(() => {
//         dispatch(getAttendance(id));
//     }, [id]);
//     return [data];
// };
//Get Today Attendance
export const useTodayAttendance = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendance);
  useEffect(() => {
    dispatch(getTodayAttendance());
  }, []);
  return [data];
};
//Get Monthly Attendance
export const useMonthlyAttendance = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendance);
  useEffect(() => {
    dispatch(getMonthlyAttendance());
  }, []);
  return [data];
};
// get Today Attendance Employee
export const useTodaysAttendanceEmployee = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendance);
  useEffect(() => {
    dispatch(getTodayAttendanceEmployee());
  }, []);
  return [data];
};
// get Monthly Attendance Employee
export const useMonthlyAttendanceEmployee = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendance);
  useEffect(() => {
    dispatch(getMonthlyAttendanceEmployee());
  }, []);
  return [data];
};
// Add Data
export const useCreateAttendance = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const addData = async (data) => {
    setLoading(true);
    data.check_in_time = new Date();
    await dispatch(addAttendance(data));
    await dispatch(getTodayAttendance());
    setLoading(false);
  };
  return [addData, loading];
};
export const useUpdateAttendance = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    data.check_out_time = new Date();
    await dispatch(editAttendance(id, data));
    await dispatch(getTodayAttendance());
  };
  return [updateData];
};
export const useGetDropdownOptions = () => {

  // const [employee] = useSelectAllEmployee();
  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();

  const [dropdownOptions, setDropdownOptions] = useState({});








  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, employee: newData });
    }
  }, [employee]);

  return [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,

  ];
};
