import React, { useEffect } from "react";
import DataTable from "../common/DataTable";
import Pagination from "../common/Pagination";
import {
    view_all_table,
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/clients_enum"
import {
    useAllClients,
    useAllClientsStats,
    useAllClientsByCondition
} from "../../shared/hooks/UseClient"
import ExportComponent from "../common/ExportComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";

function AllClientsComponent({ label, value }) {
    const [data, setPageNumber, setParams, deleteBtnClicked] =
        useAllClientsByCondition();
    const [statsdata, setParamss] = useAllClientsStats();

    const {
        loading,
        clients,
        total_clients,
        page,
        pages,
        clients_stats,
    } = data;

    console.log("Client Stats mai kya hai -", clients_stats);
    useEffect(() => {
        if (value) {
            setParams([{ label, value }]);
            setParamss([{ label, value }]);
        }

        // setParamsCandidate([{ label: "client_name", value: match.params.id }]);
    }, [value]);

    const [exportXLSXData] = UseFilter();

    const [convertToReadable, exportData] = UseDataForExcel();


    useEffect(() => {
        if (clients) {
            convertToReadable(clients, inputFields);
        }
    }, [clients]);

    const handleOnExport = () => {
        exportXLSXData(exportData, "Clients", "clients");
    };

    return (
        <>
            <ExportComponent handleOnExport={handleOnExport} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="card"
                            style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
                        >
                            <div className="card-header">
                                <div className="card-title">Clients</div>
                            </div>
                            <div className="card-body">
                                {total_clients} records found
                                <div>
                                    <DataTable
                                        keys={view_all_table}
                                        data={clients}
                                        field={LINK_URL}
                                        page={page}
                                        deleteBtnClicked={deleteBtnClicked}
                                        loading={loading}
                                        inputFields={inputFields}
                                        PAGE_TITLE={PAGE_TITLE}
                                        PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                                        hideEditBtn={true}
                                        hideDeleteBtn={true}
                                        hideQVBtn={true}
                                    />
                                    <Pagination
                                        data={clients}
                                        page={page}
                                        pages={pages}
                                        count={total_clients}
                                        setPage={setPageNumber}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllClientsComponent