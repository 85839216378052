import api from "../../domain/api";
import {
  GET_INTERVIEWS,
  ADD_INTERVIEW,
  EDIT_INTERVIEW,
  GET_INTERVIEW,
  RESET_INTERVIEW,
  GET_ALL_INTERVIEWS,
  ERROR_INTERVIEW,
  GET_ALL_INTERVIEWS_BY_JOB_POST,
  INTERVIEW_STATS_STARTED,
  INTERVIEW_STATS,
  INTERVIEW_STATS_ENDED,
} from "../types/interview_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addInterview = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/interviews`, formData);
    dispatch({
      type: ADD_INTERVIEW,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const addInterviewCandidate =
  (formData) => async (dispatch, getState) => {
    try {
      const { data } = await api.post(
        `/interviews/candidate-interview`,
        formData
      );
      dispatch({
        type: ADD_INTERVIEW,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getInterviews =
  ({ pageNumber = "", params }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_INTERVIEW,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = "";
      if (params) {
        let search_params = "";
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/interviews?&pageNumber=${pageNumber}${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/interviews?&pageNumber=${pageNumber}&${query}`;
      }
      const { data } = await api.get(URL_TO_FETCH);

      dispatch({
        type: GET_INTERVIEWS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
  export const getInterviewsStats =
  ({ params }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: INTERVIEW_STATS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));

      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = "";
      if (params) {
        let search_params = "";
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/interviews/stats/?${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/interviews/stats/?${query}`;
      }

      const { data } = await api.get(URL_TO_FETCH);

      dispatch({
        type: INTERVIEW_STATS,
        payload: data,
      });
      dispatch({
        type: INTERVIEW_STATS_ENDED,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getInterview = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_INTERVIEW,
    });
    const { data } = await api.get(`/interviews/${id}`);

    dispatch({
      type: GET_INTERVIEW,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editInterview = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/interviews/${id}`, formData);
    dispatch({
      type: EDIT_INTERVIEW,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteInterview = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/interviews/${id}`);
    dispatch(setAlert("Interview Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllInterviews =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/interviews/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_INTERVIEWS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getAllInterviewsByJobPosts = (id) => async (dispatch) => {
  try {
    const { data } = await api.get(`/interviews?select[posting_title]=${id}`);

    dispatch({
      type: GET_ALL_INTERVIEWS_BY_JOB_POST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_INTERVIEW });
};
