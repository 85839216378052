import api from "../../domain/api";
import {
  GET_CONTACTS,
  ADD_CONTACT,
  EDIT_CONTACT,
  GET_CONTACT,
  RESET_CONTACT,
  GET_ALL_CONTACTS,
  ERROR_CONTACT,
} from "../types/contact_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addContact = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/contacts`, formData);
    dispatch({
      type: ADD_CONTACT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getContacts =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CONTACT,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      const { data } = await api.get(
        `/contacts?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_CONTACTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getContact = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CONTACT,
    });
    const { data } = await api.get(`/contacts/${id}`);

    dispatch({
      type: GET_CONTACT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editContact = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/contacts/${id}`, formData);
    dispatch({
      type: EDIT_CONTACT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteContact = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/contacts/${id}`);
    dispatch(setAlert("Contact Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllContacts =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/contacts//all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_CONTACTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CONTACT });
};
