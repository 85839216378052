import {
  GET_INDUSTRYS,
  ADD_INDUSTRY,
  EDIT_INDUSTRY,
  GET_INDUSTRY,
  RESET_INDUSTRY,
  GET_ALL_INDUSTRYS,
  ERROR_INDUSTRY,
} from "../types/industry_type";

const initialState = {
  industrys: null,
  page: null,
  pages: null,
  industry: null,
  total_industrys: 0,
  loading: true,
  error: {},
  industry_message: null,
  all_industrys: null,
};

export const industry_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INDUSTRYS:
      return {
        ...state,
        industrys: payload.industrys,
        pages: payload.pages,
        page: payload.page,
        total_industrys: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_INDUSTRYS:
      return {
        ...state,
        all_industrys: payload,
        loading: false,
        error: {},
      };

    case RESET_INDUSTRY:
      return {
        ...state,
        industry: null,
        loading: true,
        error: {},
      };

    case ADD_INDUSTRY:
      return {
        ...state,
        industry_message: payload,
        loading: false,
        error: {},
      };
    case GET_INDUSTRY:
      return {
        ...state,
        industry: payload,
        loading: false,
        error: {},
      };
    case EDIT_INDUSTRY:
      return {
        ...state,
        industry_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_INDUSTRY:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                