import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import AddBtn from "../../components/common/AddBtn";
import {
  SEARCH_TERMS,
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/jobposts_enum";
import DataTable from "../../components/common/DataTable";
import {
  useAllJobposts,
  useGetDropdownOptions,
  useAllJobpostsStats,
} from "../../shared/hooks/UseJobpost";
import SidebarFilter from "../../components/common/SidebarFilter";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import ExportComponent from "../../components/common/ExportComponent";
import { Link } from "react-router-dom";

const AllJobposts = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllJobposts();
  const [statsdata, setParams] = useAllJobpostsStats();

  const {
    loading,
    jobposts,
    total_jobposts,
    page,
    pages,
    jobposts_stats_loading,
    jobposts_stats,
  } = data;

  const [dropdownOptions] = useGetDropdownOptions();
  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (jobposts) {
      convertToReadable(jobposts, inputFields);
    }
  }, [jobposts]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Requirements", "requirements");
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />

        <FilterDateComponent link="/jobposts" />

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
              />
            )}
            <div className="col-lg-9">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  <div className="row">
                    <div className="col-md-2">
                      {total_jobposts} records found
                    </div>
                    {jobposts_stats &&
                      jobposts_stats.map((item) => {
                        if (item._id) {
                          return (
                            <div className="col-md-2">
                              <div className="card">
                                <div className="card-body">
                                  <Link
                                    to={`/jobPosts?search[job_opening_status]=${item._id}`}
                                  >
                                    <div>{item._id}</div>
                                  </Link>
                                  <Link
                                    to={`/jobPosts?search[job_opening_status]=${item._id}`}
                                  >
                                    <div> {item.count} </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={jobposts}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    />
                    <Pagination
                      data={jobposts}
                      page={page}
                      pages={pages}
                      count={total_jobposts}
                      setPage={setPageNumber}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobposts;
