import React from "react";
import Header from "../../components/template/Header";
import moment from "moment";
import {
  useTodayAttendance,
  useCreateAttendance,
  useUpdateAttendance,
} from "../../shared/hooks/UseAttendance";

const TodaysAttendance = () => {
  const [data] = useTodayAttendance();
  const [addData, loading] = useCreateAttendance();
  const [updateData] = useUpdateAttendance();

  const { todays_attendance } = data;

  const CheckIn = async () => {
    await addData({});
  };
  const CheckOut = async () => {
    if (todays_attendance && todays_attendance.length > 0) {
      await updateData(todays_attendance[0]._id, {});
    }
  };

  return (
    <div>
      <Header />
      <div style={{ margin: "0 ", marginTop: "150px" }}>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">
                      <p>
                        <strong>Mark Today Attendance </strong>
                      </p>
                      {moment().format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p> Check In </p>
                        <p>
                          <strong>
                            {" "}
                            {todays_attendance &&
                              todays_attendance.length > 0 &&
                              todays_attendance[0].check_in_time
                              ? moment(
                                todays_attendance[0].check_in_time
                              ).format("HH:mm:SS A")
                              : "-"}{" "}
                          </strong>
                        </p>
                      </div>
                      <div>
                        <p> Check Out </p>
                        <p>
                          <strong>
                            {" "}
                            {todays_attendance &&
                              todays_attendance.length > 0 &&
                              todays_attendance[0].check_out_time
                              ? moment(
                                todays_attendance[0].check_out_time
                              ).format("HH:mm:SS A")
                              : "-"}{" "}
                          </strong>
                        </p>
                      </div>
                    </div>

                    {todays_attendance && todays_attendance.length == 0 ? (
                      <div className="text-center">
                        <button
                          className="btn btn-success"
                          onClick={() => CheckIn()}
                        >
                          {" "}
                          Check In{" "}
                        </button>
                      </div>
                    ) : (
                      <>
                        {todays_attendance &&
                          todays_attendance.length > 0 &&
                          !todays_attendance[0].check_out_time && (
                            <div className="text-center">
                              <button
                                className="btn btn-success"
                                onClick={() => CheckOut()}
                              >
                                {" "}
                                Check Out{" "}
                              </button>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TodaysAttendance;
