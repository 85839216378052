import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  GET_EMPLOYEE,
  RESET_EMPLOYEE,
  GET_ALL_EMPLOYEES,
  ERROR_EMPLOYEE,
} from "../types/employee_type";

const initialState = {
  employees: null,
  page: null,
  pages: null,
  employee: null,
  total_employees: 0,
  loading: true,
  error: {},
  employee_message: null,
  total_jobpost_closed_by_recruiter: 0,
  all_employees: null,
};

export const employee_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: payload.employees,
        pages: payload.pages,
        page: payload.page,
        total_employees: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_EMPLOYEES:
      return {
        ...state,
        all_employees: payload,
        loading: false,
        error: {},
      };

    case RESET_EMPLOYEE:
      return {
        ...state,
        employee: null,
        total_jobpost_closed_by_recruiter: 0,
        loading: true,
        error: {},
      };

    case ADD_EMPLOYEE:
      return {
        ...state,
        employee_message: payload,
        loading: false,
        error: {},
      };
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: payload.employee,
        total_jobpost_closed_by_recruiter: payload.total_jobpost_closed_by_recruiter,
        loading: false,
        error: {},
      };
    case EDIT_EMPLOYEE:
      return {
        ...state,
        employee_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_EMPLOYEE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
