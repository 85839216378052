export const GET_ACTIVITYS_STATED = "GET_ACTIVITYS_STATED";
export const GET_ACTIVITYS = "GET_ACTIVITYS";
export const GET_ACTIVITYS_ENDED = "GET_ACTIVITYS_ENDED";
export const ADD_ACTIVITY_STATED = "ADD_ACTIVITY_STARTED";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ADD_ACTIVITY_ENDED = "ADD_ACTIVITY_ENDED";
export const EDIT_ACTIVITY_STATED = "EDIT_ACTIVITY_STATED";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const EDIT_ACTIVITY_ENDED = "EDIT_ACTIVITY_ENDED";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_STATED = "GET_ACTIVITY_STATED";
export const GET_ACTIVITY_ENDED = "GET_ACTIVITY_ENDED";
export const RESET_ACTIVITY = "RESET_ACTIVITY";
export const ERROR_ACTIVITY = "ERROR_ACTIVITY";
export const GET_ALL_ACTIVITYS_STATED = "GET_ALL_ACTIVITYS_STATED";
export const GET_ALL_ACTIVITYS = "GET_ALL_ACTIVITYS";
export const GET_ALL_ACTIVITYS_ENDED = "GET_ALL_ACTIVITYS_ENDED";
