import api from "../../domain/api";
import {
  GET_SOURCES,
  ADD_SOURCE,
  EDIT_SOURCE,
  GET_SOURCE,
  RESET_SOURCE,
  GET_ALL_SOURCES,
  ERROR_SOURCE,
} from "../types/source_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addSource = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/sources`, formData);
    dispatch({
      type: ADD_SOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getSources =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_SOURCE,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      const { data } = await api.get(
        `/sources?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_SOURCES,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getSource = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SOURCE,
    });
    const { data } = await api.get(`/sources/${id}`);

    dispatch({
      type: GET_SOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editSource = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/sources/${id}`, formData);
    dispatch({
      type: EDIT_SOURCE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteSource = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/sources/${id}`);
    dispatch(setAlert("Source Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllSources =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/sources/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_SOURCES,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_SOURCE });
};
