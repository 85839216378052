import {
  GET_HOLIDAYS_STATED,
  GET_HOLIDAYS,
  GET_HOLIDAYS_ENDED,
  ADD_HOLIDAY_STATED,
  ADD_HOLIDAY,
  ADD_HOLIDAY_ENDED,
  EDIT_HOLIDAY_STATED,
  EDIT_HOLIDAY,
  EDIT_HOLIDAY_ENDED,
  GET_HOLIDAY_STATED,
  GET_HOLIDAY,
  GET_HOLIDAY_ENDED,
  GET_ALL_HOLIDAYS_STATED,
  GET_ALL_HOLIDAYS,
  GET_ALL_HOLIDAYS_ENDED
} from "../types/holiday_type";

const initialState = {
  holidays_loading: true,
  holidays: null,
  page: null,
  pages: null,
  total_holidays: 0,

  holiday: null,
  holiday_loading: null,

  loading: true,

  holiday_message: null,
  all_holidays: null,
  all_holidays_loading: null,
  add_holiday_loading: true,
  edit_holiday_loading: true
};

export const holiday_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_HOLIDAYS_STATED:
      return {
        ...state,
        holidays: null,
        pages: null,
        page: null,
        total_holidays: 0,
        holidays_loading: true
      };
    case GET_HOLIDAYS:
      return {
        ...state,
        holidays: payload.holidays,
        pages: payload.pages,
        page: payload.page,
        total_holidays: payload.count
      };
    case GET_HOLIDAYS_ENDED:
      return {
        ...state,
        holidays_loading: false
      };
    case GET_ALL_HOLIDAYS_STATED:
      return {
        ...state,
        all_holidays_loading: true,
        all_holidays: null
      };
    case GET_ALL_HOLIDAYS:
      return {
        ...state,
        all_holidays: payload
      };
    case GET_ALL_HOLIDAYS_ENDED:
      return {
        ...state,
        all_holidays_loading: false
      };

    case ADD_HOLIDAY_STATED:
      return {
        ...state,
        holiday_message: null,
        add_holiday_loading: true
      };
    case ADD_HOLIDAY:
      return {
        ...state,
        holiday_message: payload
      };
    case ADD_HOLIDAY_ENDED:
      return {
        ...state,
        add_holiday_loading: false
      };
    case GET_HOLIDAY_STATED:
      return {
        ...state,
        holiday: null,
        holiday_loading: true
      };
    case GET_HOLIDAY:
      return {
        ...state,
        holiday: payload
      };
    case GET_HOLIDAY_ENDED:
      return {
        ...state,
        holiday_loading: false
      };
    case EDIT_HOLIDAY_STATED:
      return {
        ...state,
        holiday_message: null,
        edit_holiday_loading: true
      };
    case EDIT_HOLIDAY:
      return {
        ...state,
        holiday_message: payload
      };
    case EDIT_HOLIDAY_ENDED:
      return {
        ...state,
        edit_holiday_loading: false
      };

    default:
      return state;
  }
};
