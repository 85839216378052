import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmployee,
  getEmployees,
  getEmployee,
  editEmployee,
  deleteEmployee,
  getAllEmployees,
} from "../../store/actions/employee_action";
import { SEARCH_TERMS } from "../enums/employees_enum";

import { useSelectAllUserrole } from "../../shared/hooks/UseUserrole";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllEmployees = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employee);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getEmployees({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteEmployee(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);
  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getEmployees({
        pageNumber,
      })
    );
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleEmployee = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(getEmployee(id));
  }, [id, window.location.search]);
  return [data];
};
// Add Data
export const useCreateEmployee = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addEmployee(data));
  };
  return [addData];
};
export const useUpdateEmployee = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editEmployee(id, data));
  };
  return [updateData];
};

export const useSelectAllEmployee = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");

  const data = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(getAllEmployees({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [userrole] = useSelectAllUserrole();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (userrole && userrole.all_userroles) {
      const newData = userrole.all_userroles.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, user_role: newData });
    }
  }, [userrole]);

  return [dropdownOptions];
};
