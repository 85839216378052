import api from "../../domain/api";
import {
    GET_ACTIVITYS_STATED,
    GET_ACTIVITYS,
    GET_ACTIVITYS_ENDED,
    ADD_ACTIVITY_STATED,
    ADD_ACTIVITY,
    ADD_ACTIVITY_ENDED,
    EDIT_ACTIVITY_STATED,
    EDIT_ACTIVITY,
    EDIT_ACTIVITY_ENDED,
    GET_ACTIVITY_STATED,
    GET_ACTIVITY,
    GET_ACTIVITY_ENDED,
    GET_ALL_ACTIVITYS_STATED,
    GET_ALL_ACTIVITYS,
    GET_ALL_ACTIVITYS_ENDED,
} from "../types/activity_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addActivity = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_ACTIVITY_STATED,
        });
        const { data } = await api.post(`/activitys`, formData);
        dispatch({
            type: ADD_ACTIVITY,
            payload: data,
        });
        dispatch({
            type: ADD_ACTIVITY_ENDED,
        });
        dispatch(getActivitys({}))
    } catch (error) {
        dispatch({
            type: ADD_ACTIVITY_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getActivitys =
    ({ pageNumber = "" }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_ACTIVITYS_STATED,
                });
                const queryParams = qs.parse(window.location.search.replace("?", ""));
                const query = qs.stringify(queryParams, {
                    encodeValuesOnly: true, // prettify url
                });

                const { data } = await api.get(
                    `/activitys?&pageNumber=${pageNumber}&${query}`
                );

                dispatch({
                    type: GET_ACTIVITYS,
                    payload: data,
                });
                dispatch({
                    type: GET_ACTIVITYS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_ACTIVITYS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };
export const getActivity = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ACTIVITY_STATED,
        });
        const { data } = await api.get(`/activitys/${id}`);

        dispatch({
            type: GET_ACTIVITY,
            payload: data,
        });
        dispatch({
            type: GET_ACTIVITY_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVITY_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const editActivity = (id, formData) => async (dispatch) => {
    try {
        dispatch({
            type: EDIT_ACTIVITY_STATED,
        });
        const { data } = await api.put(`/activitys/${id}`, formData);
        dispatch({
            type: EDIT_ACTIVITY,
            payload: data,
        });
        dispatch({
            type: EDIT_ACTIVITY_ENDED,
        });
        dispatch(getActivitys({}))
    } catch (error) {
        dispatch({
            type: EDIT_ACTIVITY_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const deleteActivity = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/activitys/${id}`);
        dispatch(setAlert("Activity Deleted Successfully", "success"));
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getAllActivitys = ({ term, value }) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_ACTIVITYS_STATED,
        });
        const { data } = await api.get(`/activitys/all?term=${term}&value=${value}`);

        dispatch({
            type: GET_ALL_ACTIVITYS,
            payload: data,
        });
        dispatch({
            type: GET_ALL_ACTIVITYS_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_ACTIVITYS_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};

export const handleErrorLocal = () => async (dispatch) => { };
