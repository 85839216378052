import api from "../../domain/api";
import {
  GET_DAILY_REPORTS_STARTED,
  GET_DAILY_REPORTS,
  GET_DAILY_REPORTS_ENDED,
  GET_CLOSED_REPORTS_STARTED,
  GET_CLOSED_REPORTS,
  GET_CLOSED_REPORTS_ENDED,
} from "../types/report_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const getDailyReports = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DAILY_REPORTS_STARTED,
    });
    const queryParams = qs.parse(window.location.search.replace("?", ""));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/reports/daily-entry?&${query}`);

    dispatch({
      type: GET_DAILY_REPORTS,
      payload: data.report,
    });
    dispatch({
      type: GET_DAILY_REPORTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_DAILY_REPORTS_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const getClosedReports = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLOSED_REPORTS_STARTED,
    });
    const queryParams = qs.parse(window.location.search.replace("?", ""));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/reports/closed-by-recruiter?&${query}`);

    dispatch({
      type: GET_CLOSED_REPORTS,
      payload: data.report,
    });
    dispatch({
      type: GET_CLOSED_REPORTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CLOSED_REPORTS_ENDED,
    });

    dispatch(handleError(error));
  }
};
