import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCandidate,
  getCandidates,
  getCandidate,
  editCandidate,
  deleteCandidate,
  getAllCandidates,
  getInterviewsByCandidate,
  getCandidatesStats,
} from "../../store/actions/candidate_action";
import { SEARCH_TERMS } from "../enums/candidates_enum";
import _debounce from "lodash/debounce";

import { useSelectAllSkillset } from "./UseSkillset";
import { useSelectAllCandidatestatus } from "./UseCandidatestatus";
import { useSelectAllSource } from "./UseSource";
import { useSelectAllEmployee } from "./UseEmployee";
import { useSelectAllClient } from "./UseClient";
import { useSelectAllJobpost } from "./UseJobpost";

// Get All Data
export const useAllCandidates = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidate);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  console.log("ID TO DELETE", deleteEntry);
  // useEffect(() => {
  //   allQuery();
  // }, [deleteEntry, window.location.search]);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCandidate(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCandidates({

        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCandidate(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get All Data
export const useAllCandidatesByCondition = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidate);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [params, setParams] = useState(null);

  useEffect(() => {
    if (params) {
      dispatch(
        getCandidates({
          pageNumber,
          params,
        })
      );
    }
  }, [params]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCandidates({
          pageNumber,
          params,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCandidate(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);
  useEffect(() => {
    if (params) {
      dispatch(
        getCandidates({
          pageNumber,
          params,
        })
      );
    }
  }, [pageNumber, window.location.search]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, setParams, deleteBtnClicked];
};
// Get All Data
export const useAllCandidatesStats = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidate);
  const [params, setParams] = useState(null);

  useEffect(() => {
    dispatch(
      getCandidatesStats({
        params,
      })
    );
  }, [params]);

  useEffect(() => {
    dispatch(
      getCandidatesStats({
        params,
      })
    );
  }, [window.location.search]);

  return [data, setParams];
};

// Get Single Data
export const useSingleCandidate = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidate);
  useEffect(() => {
    dispatch(getCandidate(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCandidate = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addCandidate(data));
  };
  return [addData];
};
// Update Data
export const useUpdateCandidate = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editCandidate(id, data));
  };
  return [updateData];
};
// Get All Data
export const useSelectAllCandidate = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.candidate);
  useEffect(() => {
    dispatch(getAllCandidates({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
// Get All Data
export const useInterviewsByCandidate = (id) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.candidate);
  useEffect(() => {
    dispatch(getInterviewsByCandidate(id));
  }, []);
  return [data];
};
export const useGetDropdownOptions = () => {
  const [skillset] = useSelectAllSkillset();
  const [candidatestatus] = useSelectAllCandidatestatus();
  const [source] = useSelectAllSource();
  // const [employee] = useSelectAllEmployee();
  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();
  const [client, setClientSearchField, setClientSearchValue] = useSelectAllClient();

  const [jobpost] = useSelectAllJobpost();
  const [dropdownOptions, setDropdownOptions] = useState({});


  useEffect(() => {
    if (skillset && skillset.all_skillsets) {
      const newData = skillset.all_skillsets.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, skill_set: newData });
    }
  }, [skillset]);

  useEffect(() => {
    if (candidatestatus && candidatestatus.all_candidatestatuss) {
      const newData = candidatestatus.all_candidatestatuss.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_status: newData });
    }
  }, [candidatestatus]);

  useEffect(() => {
    if (source && source.all_sources) {
      const newData = source.all_sources.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, source: newData });
    }
  }, [source]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, recruiter: newData });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.client_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client_name: newData });
    }
  }, [client]);

  useEffect(() => {
    if (jobpost && jobpost.all_jobposts) {
      const newData = jobpost.all_jobposts.map((item) => {
        return { label: item.posting_title, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, position_name: newData });
    }
  }, [jobpost]);

  return [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
  ];
};
