import moment from "moment";
export const PAGE_TITLE = "Documents";
export const PAGE_SINGLE_TITLE = "Document";
export const LINK_URL = "docs";
export const SEARCH_TERMS = ["document_name", "meta_title"];
export const inputFields = {
    document_name: {
        type: "string",
        required: true,
        title: "Document Name",
    },
    document_file: {
        type: "gallery",
        required: false,
        title: "Multiple Document Files",
        inputType: "text",
    },
    // file: {
    //     type: "file",
    //     required: false,
    //     title: "Attachment File",
    //     inputType: "text",
    // },
};
export const initialValues = {
    document_name: "",
    document_file: "",
};

export const view_all_table = [
    { name: "Document Name", value: "document_name" },
];

export const SIDEBAR_OPTIONS = [

    {
        id: "document_name",
        field: "document_name",
        label: "Document Name",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
]

